import {TripStatus} from '../../config/constants'
import { ReactComponent as Check } from '../../assets/icons/status-accept.svg'
import { ReactComponent as OnRoute } from '../../assets/icons/status-accept.svg'
import { ReactComponent as Confirm } from '../../assets/icons/status-accept.svg'
import { ReactComponent as Arrived } from '../../assets/icons/status-arrived.svg'
import { ReactComponent as Cancel } from '../../assets/icons/status-cancel.svg'
import { ReactComponent as NoShow } from '../../assets/icons/status-noshow.svg'
import {twMerge} from 'tailwind-merge'

const TripStatusCell = ({ status, className }) => {

    const statusMap = {
        [TripStatus.ACCEPT]: {
            label: "Boeking geaccepteerd",
            icon: <Check/>
        },
        [TripStatus.AUTOPLAN]: {
            label: "Boeking geaccepteerd",
            icon: <Check/>
        },
        [TripStatus.PLAN]: {
            label: "Boeking geaccepteerd",
            icon: <Check/>
        },
        [TripStatus.ASSIGN]: {
            label: "Boeking geaccepteerd",
            icon: <Check/>
        },
        [TripStatus.OUTSOURCED]: {
            label: "Boeking geaccepteerd",
            icon: <Check/>
        },
        [TripStatus.REJECT]: {
            label: "Boeking geaccepteerd",
            icon: <Check/>
        },
        [TripStatus.TOBEOUTSOURCED]: {
            label: "Boeking geaccepteerd",
            icon: <Check/>
        },
        [TripStatus.WITHDRAW]: {
            label: "Boeking geaccepteerd",
            icon: <Check/>
        },
        [TripStatus.CONFIRM]: {
            label: "Chauffeur onderweg",
            icon: <Confirm/>
        },
        [TripStatus.CKI]: {
            label: "Onderweg",
            icon: <OnRoute/>
        },
        [TripStatus.ARCHIVED]: {
            label: "",
            icon: null
        },
        [TripStatus.CKO]: {
            label: "Op bestemming",
            icon: <Arrived/>
        },
        [TripStatus.CANCEL]: {
            label: "Geannuleerd",
            icon: <Cancel/>
        },
        [TripStatus.NOSHOW]: {
            label: "Loosmelding",
            icon: <NoShow/>
        },
        "undefined": {
            label: "Onbekend",
            icon: null
        }
    }

    return (
        <div className={twMerge(
            "flex flex-row items-center justify-start w-full py-6 font-inter font-semibold text-primary-600 px-4 space-x-3",
            className)}
        >
            {statusMap[status].icon}
            <span>{statusMap[status].label}</span>
            {(status === TripStatus.CKI || status === TripStatus.CONFIRM) &&
                <div className="w-2 h-2 bg-secondary-300 rounded-full"/>}
        </div>
    )
}

export default TripStatusCell
