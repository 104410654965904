import {twMerge} from 'tailwind-merge';

const Badge = ({ children, ...props }) => {
    const { badge, className } = props
    return (
        <div className={
            twMerge(
                "px-3 h-6 font-inter text-xs text-primary-500 font-bold flex items-center justify-center bg-secondary-300 rounded-[6px]",
                className)}
        >
            {badge}
        </div>
    )
}

export default Badge
