import {Button, States} from '../atoms/Button';
import {Alert} from './index'


const DialogConfirm = ({ onClose, isOpen, icon, title, body, cancel, confirm, onConfirm }) => {
    return (
        <Alert
            onClose={onClose}
            isOpen={isOpen}
            hideCloseButton={true}
            className="bg-white rounded-[16px] flex flex-col items-center px-2.5 pt-11 pb-4 mx-2.5"
        >
            <div className="flex flex-col space-y-2 items-center px-10">
                {!!icon && icon}
                <h2 className="title">{title}</h2>
                <span className="body text-center">{body}</span>
            </div>

            <div className="flex flex-col space-y-2 items-center justify-center mt-10 w-full">
                <Button state={States.DEFAULT} className="w-full" onClick={() => {
                    onClose()
                    !!onConfirm && onConfirm()
                }}>
                    {confirm}
                </Button>
                <Button state={States.OUTLINED} className="w-full bg-transparent border-none text-sm text-grey-500 hover:text-grey-700" onClick={onClose}>
                    {cancel}
                </Button>
            </div>
        </Alert>
    )
}

export default DialogConfirm
