import { useTranslation } from 'react-i18next'
import useModal from '../../utils/hooks/useModal'
import useApi, { HTTPMethod } from '../../utils/hooks/useApi'
import { useTrips } from '../../context/TripContext'
import {useLocation, useNavigate} from 'react-router-dom'
import React, { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import {BASE_URL, ListTripAction, TripStatus, TripType} from '../../config/constants'
import { twMerge } from 'tailwind-merge'
import { ActionsDialog, DialogConfirm } from './index'
import { generateGUID } from '../../utils/helper'
import UpdateWizard from '../../screens/UpdateWizard'
import BookWizard from '../../screens/BookWizard'
import { ReactComponent as Delete } from '../../assets/icons/trash.svg'
import { ReactComponent as Reload } from '../../assets/icons/reload.svg'
import { ReactComponent as Trash } from '../../assets/icons/trash.svg'
import { ReactComponent as Error } from '../../assets/icons/error.svg'

const TripActions = ({trip}) => {
    const isPhoneBooked = trip?.booking?.bookingChannelType?.name === 'PHONE'
    const { t } = useTranslation()
    const { isOpen, closeModal, openModal } = useModal()
    const { callApi } = useApi()
    const { listTrips, updateTripConfig } = useTrips()
    const location = useLocation()
    const navigate = useNavigate()
    const { isOpen: isOpenActions, closeModal: closeActions, openModal: openActions } = useModal()
    const { isOpen: isOpenUpdate, closeModal: closeUpdate, openModal: openUpdate } = useModal()
    const { isOpen: isOpenBook, closeModal: closeBook, openModal: openBook } = useModal()
    const [ wizardMode, setWizardMode ] = useState(null)

    const tripId = location.pathname.split('/').pop()

    const handleEdit = (edit) => {
        updateTripConfig('locationFrom', trip?.locationFrom)
        updateTripConfig('locationTo', trip?.locationTo)
        updateTripConfig('phoneNumber', trip?.phoneNumber)
        updateTripConfig('isReturn', false)
        updateTripConfig('tripAttributes', trip?.tripAttributes)
        updateTripConfig('id', trip?.id)

        if (edit) {
            updateTripConfig('pickupDateTime', trip?.pickupDateTime)
            openActions()
        } else {
            openBook()
        }
    }

    const { mutate } = useMutation({
        queryKey: ["useDeleteTrip"],
        mutationFn: async (payload) => {
            navigate(location.pathname.replace(`/${tripId}`, ''))
            return await callApi(
                "trip/SaveTripStorageModels",
                payload,
                HTTPMethod.POST,
                true, BASE_URL
            )
        },
        onSuccess: (_data) => {
            listTrips(ListTripAction.ALL)
        },
        onError: (error) => {
            console.log("error", error)
        }
    })

    return (
        <>
            <div className="flex flex-row border-x border-b rounded-b-[20px] border-grey-400 overflow-hidden shrink-0">
                {trip?.tripOperation.tripStatusType.name === TripStatus.CANCEL ||
                trip?.tripOperation.tripStatusType.name === TripStatus.NOSHOW ||
                trip?.tripOperation.tripStatusType.name === TripStatus.ARCHIVED ||
                trip?.tripOperation.tripStatusType.name === TripStatus.CKO
                    ? (
                        <button
                            onClick={() => handleEdit(false)}
                            className="action-link py-4"
                        >
                            <Reload className="fill-primary-400 mr-2 w-8 h-8"/> Opnieuw boeken
                        </button>
                    ) : (
                        <>
                            {!isPhoneBooked && (
                            <button
                                type="button"
                                onClick={() => handleEdit(true)}
                                className={twMerge(
                                    "font-inter font-semibold text-base text-center hover:bg-primary-100 w-full h-full py-5",
                                    "text-primary-400")}
                                aria-label={t('aria.button.trip.edit')}
                            >
                                Ik wil de rit wijzigen
                            </button>)}

                            <button
                                type="button"
                                onClick={openModal}
                                className={twMerge(
                                    isPhoneBooked ?  "w-full" : "w-[90px] border-l border-l-grey-400",
                                    "h-full flex items-center justify-center group py-4 text-secondary-600 font-semibold")}
                                aria-label={t('aria.button.trip.delete')}
                            >
                                <Delete id="icon-delete" className="fill-secondary-600 group-hover:fill-secondary-300"/>
                                {isPhoneBooked && <span className="mt-1">{t('aria.button.trip.delete')}</span>}
                            </button>
                        </>
                    )
                }
            </div>

            {isPhoneBooked && (
                <div
                    className="mt-5 border py-4 px-3 flex flex-row rounded-[20px] bg-[#F2FBFF] items-center"
                >
                    <Error className="fill-primary-500 mr-3"/>
                    <div className="flex flex-col">
                        <h3 className="font-inter font-semibold text-grey-600">{t("trip.callcenter.title")}</h3>
                        <span className="font-inter text-sm text-grey-600">{t("trip.callcenter.body")}</span>
                    </div>
                </div>)}

            <DialogConfirm
                icon={<div className="w-12 h-12 bg-secondary-300 flex items-center justify-center rounded-full">
                    <Trash className="fill-primary-500"/>
                </div>}
                isOpen={isOpen}
                onClose={closeModal}
                onConfirm={() =>
                    mutate({
                        correlationId: generateGUID(),
                        id: generateGUID(),
                        invokerId: generateGUID(),
                        records: [
                            {
                                id: tripId,
                                version: generateGUID(),
                                isChanged: true,
                                tripOperation: {
                                    id: trip?.tripOperation?.id,
                                    version: generateGUID(),
                                    isChanged: true,
                                    tripStatusType: {
                                        id: "abf110fb-bd0e-4ab3-bbfb-bda60322fc6d",
                                        version: "15d4dc1e-3b22-436f-86e0-80448beb6bdd"
                                    }
                                }
                            }
                        ],
                    })}
                title={t('dialog.cancel.title')}
                body={t('dialog.cancel.body')}
                confirm={t('dialog.cancel.button.confirm')}
                cancel={t('dialog.cancel.button.cancel')}
            />
            <ActionsDialog
                isOpen={isOpenActions}
                onClose={closeActions}
                onClick={action => {
                    setWizardMode(action)
                    openUpdate()
                }}
            />
            <UpdateWizard isOpen={isOpenUpdate} onClose={closeUpdate} mode={wizardMode}/>
            <BookWizard isOpen={isOpenBook} onClose={closeBook} tripType={TripType.ONE_WAY}/>
        </>
    )
}

export default TripActions
