import { motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'
import { useEffect, useState } from 'react'
import { ReactComponent as Search } from '../../assets/icons/search.svg'
import { ReactComponent as Swap } from '../../assets/icons/swap.svg'
import { Poi } from '../atoms'
import useLocationSearch from '../../utils/hooks/useLocationSearch'

const ViewStates = {
    EMPTY: 'empty',
    FILLED: 'filled',
    ACTIVE: 'active',
}

const AddressButton = ({ showSwitcher = false, onSwap, isHome = false, ...props }) => {
    const { className, placeholder, onClick, location } = props
    const [s, setS] = useState(location ? ViewStates.FILLED : ViewStates.EMPTY)
    const { formattedTitle, formattedAddress } = useLocationSearch()

    useEffect(() => {
        setS(location ? ViewStates.FILLED : ViewStates.EMPTY)
    }, [location])

    const content = {
        [ViewStates.EMPTY]: {
            style:
                twMerge(
                "address-item",
                    'bg-grey-200 border-grey-300 border text-left',
                    className
                ),
            icon: <div className="bg-grey-400 w-12 h-12 rounded-full flex items-center justify-center"><Search/></div>
        },
        [ViewStates.FILLED]: {
            style:
                twMerge(
                    "address-item",
                    'bg-grey text-grey-500 text-left',
                    className
                ),
            icon: <div className="bg-primary-200 shrink-0 w-12 h-12 rounded-full flex items-center justify-center">
                <Poi acronym={location?.poiTypeCode || (isHome ? 'HOME' : 'POS')} />
            </div>
        },
        [ViewStates.ACTIVE]: {
            style:
                twMerge(
                    "address-item",
                    'bg-grey text-grey-500',
                    className
                ),
            icon: <Search/>
        }
    }

    return (
        <motion.div
            className={twMerge("relative w-full cursor-pointer select-none", className)}
        >
            <button className={content[s].style} onClick={onClick} tabIndex={0}>
                {content[s].icon}
                {!location
                    ? <span className="font-inter font-semibold text-grey-600">{placeholder}</span>
                    : <div className={twMerge("flex flex-col space-y-0 w-[70%] overflow-hidden", className)}>
                        <span className="text-primary-500 font-inter font-semibold md:truncate text-base">{formattedTitle(location)}</span>
                        <span className="text-grey-600 font-inter font-normal text-sm truncate">{formattedAddress(location)}</span>
                    </div>

                    // <ValueLabel className="w-[80%]" title={location?.name} subtitle={location?.address}/>
                }
            </button>
            {showSwitcher &&
                <div className="flex absolute cursor-pointer right-4 items-center justify-center h-full top-0 lg:rotate-90" onClick={() => !!onSwap && onSwap()}>
                    <Swap/>
                </div>
            }
        </motion.div>
    )
}

export default AddressButton
