import {DialogConfirm, NavBar} from '../components/molecules'
import { Link, Outlet } from 'react-router-dom'
import { ReactComponent as Logo } from '../assets/logo/concession_b.svg'
import { useEffect } from 'react'
import { useTrips } from '../context/TripContext'
import { useAccount } from '../context/AccountContext'
import { ListTripAction } from '../config/constants'
import useModal from '../utils/hooks/useModal'
import {useMsal} from '@azure/msal-react'
import {useTranslation} from 'react-i18next'
import { ReactComponent as Lock } from '../assets/icons/lock.svg'

const Layout = () => {
    const { listTrips, initializeTrip } = useTrips()
    const { selectedAccount, accounts } = useAccount()
    const {isOpen, closeModal, openModal} = useModal()
    const {instance} = useMsal()
    const { t } = useTranslation()

    useEffect(() => {
        if (selectedAccount) {
            initializeTrip()
            listTrips(ListTripAction.ALL)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount])

    return (
        <div className="flex flex-col flex-1 h-full relative">
            <div className="w-full h-[90px] centered bg-primary-500 z-20">
                <div className="flex flex-row justify-center md:justify-between items-center container fixed top-0 h-[90px] centered">
                    <Link to={'/boeken'}><Logo className="hidden md:block shrink-0"/></Link>
                    { accounts.length > 0
                        ? <NavBar/>
                        : <button
                            className="text-xs font-inter font-semibold text-white hover:underline"
                            onClick={openModal}
                        >
                            Uitloggen
                    </button>}
                </div>
            </div>
            <div className="bg-grey-300 z-10 relative flex-1 pb-16 mt-[90px]">
                <Outlet/>
            </div>
            <DialogConfirm
                icon={<div className="w-12 h-12 bg-primary-200 flex items-center justify-center rounded-full">
                    <Lock className=""/>
                </div>}
                isOpen={isOpen}
                onClose={closeModal}
                onConfirm={() => instance.logout()}
                title={t('dialog.logout.title')}
                body={t('dialog.logout.body')}
                confirm={t('dialog.logout.button.confirm')}
                cancel={t('dialog.logout.button.cancel')}
            />
        </div>
    )
}

export default Layout
