import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { InputField, AuthForm } from '../molecules'
import { Button, States } from '../atoms/Button'
import { ReactComponent as Lock } from '../../assets/icons/lock.svg'
import { useState } from 'react'

const ViewStates = {
    EMPTY: 'active',
}

const ConfirmForm = () => {
    const { t } = useTranslation()
    const [viewState] = useState(ViewStates.EMPTY)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const content = {
        [ViewStates.EMPTY]: {
            title: t('confirm.title'),
            body: t('confirm.body'),
            icon: <Lock className="w-8 h-8" />,
        }
    }

    const schema = Yup.object().shape({
        code: Yup.string()
            .required(t('error.required'))
    })

    const buttonState = (isValid, dirty) => {
        if (isSubmitting) return States.LOADING
        if (!isValid || !dirty) return States.DISABLED
        return States.DEFAULT
    }

    return (
        <AuthForm
            className="mt-12 mx-2.5 mb-6"
            title={content[viewState].title}
            body={content[viewState].body}
            icon={content[viewState].icon}
            showBack
        >
            {
                viewState !== ViewStates.MAIL_SENT &&
                    <Formik
                        initialValues={{
                            code: '',
                        }}
                        onSubmit={({ code }) => {
                            //alert(JSON.stringify(code))
                            setIsSubmitting(true)
                        }}
                        validationSchema={schema}
                        validateOnBlur={true}
                        validateOnChange={true}
                    >
                        {
                            ({ isValid, dirty }) =>
                                <Form className="space-y-9">
                                    <InputField
                                        name="code"
                                        placeholder={t('confirm.form.code.placeholder')}
                                        label={t('confirm.form.code.label')}
                                        type="number"
                                    />
                                    <div className="flex flex-row items-center justify-center w-full">
                                        <Button
                                            state={buttonState(isValid, dirty)}
                                            type="submit"
                                            className="w-full md:w-[350px]"
                                        >
                                            {t('confirm.button.send')}
                                        </Button>
                                    </div>
                                </Form>
                        }
                    </Formik>
            }
        </AuthForm>
    )
}

export default ConfirmForm
