import { twMerge } from 'tailwind-merge'
import { motion } from 'framer-motion'
import { ReactComponent as Loader } from '../../assets/icons/loader.svg'

const States = {
    ACTIVE: 'active',
    DISABLED: 'disabled',
    DEFAULT: 'default',
    LOADING: 'loading',
    OUTLINED: 'outlined',
}

const Button = ({ children, className, ...props }) => {
    const { state } = props

    const style = {
        [States.ACTIVE]: twMerge(
            'bg-primary-600 text-white',
            className
        ),
        [States.DISABLED]: twMerge(
            'bg-primary-100 hover:text-grey-100 text-primary-300 disabled:cursor-not-allowed disabled:bg-primary-100 disabled:text-primary-300',
            className
        ),
        [States.DEFAULT]: twMerge(
            'bg-primary-300 text-primary-600',
            className
        ),
        [States.LOADING]: twMerge(
            'bg-primary-100 text-primary-600',
            className
        ),
        [States.OUTLINED]: twMerge(
            'border-primary-500 bg-transparent text-primary-500 border-2',
            className
        ),
    }

    return (
        <motion.button
            disabled={state === States.DISABLED || state === States.LOADING}
            tabIndex={state === States.DISABLED ? -1 : 0}
            className={twMerge(
                'rounded-[12px] font-inter font-bold tracking-wide flex flex-row items-center justify-center text-sm h-[60px]',
                style[state],
                state !== States.LOADING ? 'py-5' : 'py-1',
            )}
            {...props}
        >
            { state === States.LOADING ? <Loader className="w-12 h-12 fill-primary-600"/> : children}
        </motion.button>
    )
}

export {
    States,
    Button
}
