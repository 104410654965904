import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { useAccount} from '../../context/AccountContext'
import { ReactComponent as Checkmark } from '../../assets/icons/checkmark.svg'
import { ReactComponent as Chevron } from '../../assets/icons/chevron.svg'
import { twMerge } from 'tailwind-merge'
import { useTrips } from '../../context/TripContext'

const ListBox = ({className}) => {
    const {
        accounts,
        selectedAccount,
        setSelectedAccount,
    } = useAccount()
    const { resetTrips } = useTrips()

    return (
        <div className={twMerge("top-16 w-full", className)}>
            <Listbox value={selectedAccount} onChange={(value) =>
                {
                    resetTrips()
                    setSelectedAccount(value)
                }}
            >
                <div className="relative mt-1">
                    <Listbox.Button
                        className="flex flex-row items-center relative w-full cursor-default bg-white border border-grey-400 p-1.5 rounded-full">
                        <div className="bg-primary-200 w-12 h-12 rounded-full flex items-center justify-center font-inter font-semibold text-base uppercase">{selectedAccount?.fullName.charAt(0)}</div>
                        <span className="ml-3 text-base font-inter font-semibold text-primary-500 flex-1 text-left">{selectedAccount?.fullName}</span>
                        <Chevron className="fill-primary-500"/>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options
                            className="z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {accounts?.map((person, personIdx) => (
                                <Listbox.Option
                                    key={personIdx}
                                    className={({active}) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                            active ? 'bg-primary-100 text-amber-900' : 'text-gray-900'
                                        }`
                                    }
                                    value={person}
                                >
                                    {({selectedAccount}) => (
                                        <>
                                            <span className={`text-base font-inter font-semibold text-primary-500 block truncate ${selectedAccount ? 'font-medium' : 'font-normal'}`}>
                                                {person?.fullName}
                                            </span>
                                            {selectedAccount ? (
                                                <span
                                                    className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                        <Checkmark className="fill-primary-500"/>
                                                        {/*<CheckIcon className="h-5 w-5" aria-hidden="true" />*/}
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    )
}

export default ListBox
