import { useTranslation } from 'react-i18next'
import { useEffect, useRef} from 'react'
import { ReactComponent as Close } from '../assets/icons/clost.svg'
import { ReactComponent as Search } from '../assets/icons/search.svg'
import { Modal } from '../components/molecules'
import { twMerge } from 'tailwind-merge'
import { Poi, ValueLabel } from '../components/atoms'
import useKeyDown from '../utils/hooks/useKeyDown'
import useLocationSearch from '../utils/hooks/useLocationSearch'
import Loader from '../components/atoms/Loader'
import useDisableBodyScroll from '../utils/hooks/useDisableBodyScroll'

const LocationCell = ({ location, onClick }) => {
    const { formattedTitle, formattedAddress } = useLocationSearch()
    return (
        <div className={"flex flex-row w-full p-4 items-center border-b border-b-grey-400 space-x-4"} onClick={() => onClick(location)}>
            <div className="bg-primary-200 shrink-0 w-12 h-12 rounded-full flex items-center justify-center">
                <Poi acronym={location?.poiTypeCode || "POS"} />
            </div>
            <ValueLabel title={formattedTitle(location)} subtitle={formattedAddress(location)}/>
        </div>
    )
}

const LocationSearch = ({ onClose, isOpen, title, onClick, defaultValue }) => {
    const { t } = useTranslation()
    const {
        isLoading,
        data,
        request,
        setSearchRequest,
        reset,
        formattedTitle,
    } = useLocationSearch()

    const inputElement = useRef(null)

    useDisableBodyScroll(isOpen)

    useEffect(() => {
        if (isOpen && defaultValue) {
            setSearchRequest(formattedTitle(defaultValue))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    useKeyDown(() => {
        handleClose()
    }, ["Escape"])

    const handleClick = (location) => {
        // if location does not contain houseNumber, then we need to fetch it
        if (!location?.houseNumber) {
            if (location.type === 'straat') {
                inputElement.current.value = location?.street + ", " + location?.place
                setSearchRequest(location?.street + " " + location?.place)
            }
            setSearchRequest(location?.type === "huisnummerreeks" ? location?.zip : location?.street + " " + location?.place)
        } else {
            !!onClick && onClick(location)
            handleClose()
        }
    }

    const handleChange = (e) => {
        setSearchRequest(e.target.value)
    }

    const handleClose = () => {
        reset()
        onClose()
    }

    const clearSearch = () => {
        inputElement.current.value = ""
        reset()
    }

    return (
        <>
        <Modal
            onClose={() => {
                inputElement.current?.focus()
                handleClose()
            }}
            isOpen={isOpen}
            hideCloseButton={true}
            className="absolute top-0 bottom-0 w-full h-full md:w-full max-w-full"
        >
            <div className="flex flex-col flex-start bg-white h-full flex-1">
                <div className="relative border-b-grey-400 border-b flex flex-row items-center px-4">
                    <div className="absolute w-8 h-8" onClick={handleClose}><Close/></div>
                    <h1 className="w-full text-center font-inter font-semibold text-primary-500 py-5">{title}</h1>
                </div>

                <div className="flex relative">
                    <Search className="absolute top-4 left-4 shrink-0"/>
                    <input
                        ref={inputElement}
                        //ref={callbackRef}
                        type="text"
                        //tabIndex={-1}
                        //onFocus={() => {
                            // window.scrollTo(0, 2000);
                            // document.body.scrollTop = 0;

                        //}}
                        placeholder={t('booking.step.address.placeholder')}
                        className={twMerge(
                            "w-full px-16 py-5 border-b-2 border-b-primary-500 rounded-none flex font-inter text-base font-semibold text-primary-500",
                            "focus:border-b-primary-300 bg-white focus:ring-0 focus:outline-none"
                        )}
                        defaultValue={request !== undefined ? request : ""}
                        onChange={handleChange}
                        autoFocus
                    />
                    {request?.length > 0 &&
                        <button
                            onClick={clearSearch}
                            className="absolute top-4 right-4 bg-primary-100 rounded-full w-8 h-8 shrink-0 flex md:hidden items-center justify-center">
                            <Close className="w-4 h-4"/>
                        </button>}
                </div>

                { isLoading
                    ? <Loader/>
                    : <div className="overflow-auto flex-1 w-full cursor-pointer">
                        {data?.records?.map((location, index) =>
                            <LocationCell location={location} key={index} onClick={handleClick}/>
                        )}
                    </div> }
            </div>
        </Modal>
        </>
    )
}

export default LocationSearch
