import React from 'react'

import { ReactComponent as CHILD_FREE} from '../../assets/icons/indications/toddler.svg'
import { ReactComponent as CHILD} from '../../assets/icons/indications/kid.svg'
import { ReactComponent as HEARING_IMPAIRED} from '../../assets/icons/indications/deaf.svg'
import { ReactComponent as VISUALLY_IMPAIRED} from '../../assets/icons/indications/visual-impaired.svg'
import { ReactComponent as VERBALLY_IMPAIRED} from '../../assets/icons/indications/verbally-impaired.svg'
import { ReactComponent as OXYGEN} from '../../assets/icons/indications/oxygen.svg'
import { ReactComponent as SCOOTMOBILE} from '../../assets/icons/indications/scootmobiel.svg'
import { ReactComponent as WALKER} from '../../assets/icons/indications/walker.svg'
import { ReactComponent as WALKER_NOWHEELS} from '../../assets/icons/indications/walker-no-wheels.svg'
import { ReactComponent as SOCIAL_ASSISTANT} from '../../assets/icons/indications/social-assistant.svg'
import { ReactComponent as SOCIAL_ASSISTANT_FREE} from '../../assets/icons/indications/social-assistant.svg'
import { ReactComponent as MANDATORY_MEDICAL_ASSISTANT} from '../../assets/icons/indications/medical-assistant.svg'
import { ReactComponent as WHEELCHAIR} from '../../assets/icons/indications/wheelchair.svg'
import { ReactComponent as LONG_WHEELCHAIR} from '../../assets/icons/indications/wheelchair-long.svg'
import { ReactComponent as ELECTRIC_WHEELCHAIR} from '../../assets/icons/indications/electric-wheelchair.svg'
import { ReactComponent as GUIDE_DOG} from '../../assets/icons/indications/guided-dog.svg'


const iconMappings = {
    VISUALLY_IMPAIRED,
    HEARING_IMPAIRED,
    VERBALLY_IMPAIRED,
    WHEELCHAIR,
    ELECTRIC_WHEELCHAIR,
    // FOLDING_WHEELCHAIR,
    // DEVIANT_WHEELCHAIR,
    // BIG_WHEELCHAIR,
    LONG_WHEELCHAIR,
    WALKER,
    WALKER_NOWHEELS,
    SCOOTMOBILE,
    OXYGEN,
    GUIDE_DOG,
    // SOLO,
    // MANDATORY_BUS,
    // FRONTSEAT,
    // MANDATORY_TAXI,
    // LOW_ENTRANCE,
    // ROOM_ROOM,
    MANDATORY_MEDICAL_ASSISTANT,
    SOCIAL_ASSISTANT_FREE,
    SOCIAL_ASSISTANT,
    //PT_ASSISTANT,
    CHILD_FREE,
    CHILD,
    // NEED_WHEELCHAIR,
    // CUSTOMER,
    // PAX,
    // PT_AVAILABLE,
    // DESIREDPICKUPTIME,
    // DEVICES,
    // LARGE_DEVICES,
    // ATTENDANT,
    // DIRECTBOOKING,
    // PRIORITY,
    // FORCEDIRECT,
}




const TripAttributeIcon = ({ acronym }) => {
    const IconComponent = iconMappings[acronym]//iconMappings[!!acronym ? acronym.toUpperCase() : POS]

    if (!IconComponent) {
        // Return a default or empty icon if acronym is not recognized
        return <></>
    }

    return <IconComponent className="w-8 h-8 shrink-0" />
};

export default TripAttributeIcon
