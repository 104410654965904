import React, {useEffect, useState} from 'react'
import {useCustomerEditorModel} from '../utils/fetch'
import {useIsAuthenticated, useMsal} from '@azure/msal-react'
import {InteractionStatus} from '@azure/msal-browser'

const AccountContext = React.createContext(null)

export const AccountProvider = ({ children }) => {
    const [locations, setLocations] = useState()
    const [accounts, setAccounts] = useState()
    const [selectedAccount, setSelectedAccount] = useState()
    const customerEditorMutation = useCustomerEditorModel()//useCustomerSubContractEditor()
    const [isLoading, setIsLoading] = useState(true)
    const {accounts: user, inProgress} = useMsal()
    const isAuthenticated = useIsAuthenticated()

    useEffect( () => {
        if (user && isAuthenticated && inProgress === InteractionStatus.None) {
            customerEditorMutation.mutate({email: user[0].username})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, isAuthenticated, inProgress])

    useEffect(() => {
        let customers = []

        if (customerEditorMutation.data && customerEditorMutation.data?.records?.length > 0) {
            customers = customerEditorMutation.data?.records
        }
        setAccounts(customers)
        setIsLoading(customerEditorMutation.isLoading)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerEditorMutation.data])

    useEffect(() => {
        if (accounts && accounts.length > 0) {
            setSelectedAccount(accounts[0])
        }
    }, [accounts])

    const getLocationOfType = (type, account) => (
        (account && account.locations)
            ? account.locations.find((location) => location.customerLocationType.name === type)?.location
            : "-"
    )

    return (
        <AccountContext.Provider value={{
            locations,
            setLocations,
            accounts,
            setAccounts,
            selectedAccount,
            setSelectedAccount,
            isLoading,
            getLocationOfType
        }}>
            {children}
        </AccountContext.Provider>
    )
}

export const useAccount = () => {
    const context = React.useContext(AccountContext)

    if (context === undefined) {
        throw new Error('useBook must be used within a AccountProvider')
    }

    return context
}
