const RadioInput = ({ ...props }) => {
    const {id, name, value, label, checked, defaultChecked, onChange} = props

    return (
        <div className="flex items-center">
            <input
                id={id}
                type="radio"
                value={value}
                name={name}
                checked={checked}
                aria-checked={checked}
                onChange={onChange}
                defaultChecked={defaultChecked}
                className="w-5 h-5 border-grey-200 focus:ring-primary-600 focus:ring-0"
            />
            <label htmlFor={id} className="ml-2 text-sm font-inter text-grey-600">{label}</label>
        </div>
    )
}

export default RadioInput
