import toast, { ToastBar, Toaster } from 'react-hot-toast'
import React from 'react'
import { ReactComponent as Calendar } from '../../assets/icons/calendar.svg'
import { ReactComponent as Time } from '../../assets/icons/time.svg'
import { ReactComponent as Trip } from '../../assets/icons/trip.svg'
import { ReactComponent as Check } from '../../assets/icons/check.svg'
import { ReactComponent as Error } from '../../assets/icons/error.svg'
import { ReactComponent as Info } from '../../assets/icons/eye-open.svg'
//import { ReactComponent as Close } from '../../assets/icons/clost.svg'

const Toasty = () =>

    <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        toastOptions={{
            // Define default options
            className: 'w-full',
            duration: 3000,
            style: {
                zIndex: '9999 !important',
                background: '#F4F4F4',
                color: '#333',
                fontFamily: 'Inter, sans-serif',
                fontSize: '14px',
                borderRadius: '9999px',
                padding: 0,
                maxWidth: '400px',
            },

            // Default options for specific types
            success: {
                style: {
                    background: '#DBF3FF',
                    color: '#1B147E',

                },
            },

            error: {
                style: {
                    background: '#fcbec1',
                    color: '#1B147E',
                },
            }
        }}
    >

        {(t) => {
            const toastIcon = {
                calendar: <Calendar/>,
                time: <Time/>,
                trip: <Trip/>,
                success: <Check/>,
                error: <Error className="fill-secondary-600"/>,
                loading: <Info/>,
            }

            return (
                <ToastBar toast={t}>
                    {({icon, message}) => {
                        return (
                            <div className="relative flex flex-row items-center justify-between px-4 py-4 w-full">
                                <div className="flex flex-row flex-1 items-center justify-start">
                                    <div className="mr-0">{toastIcon[t.type]}</div>
                                    <span className="font-inter font-semibold">{message}</span>
                                </div>

                                {t.type !== 'loading' && (
                                    <button className="right-1 cursor-pointer" onClick={() => toast.dismiss(t.id)}>
                                        {/*<Close className="w-6 h-6"/>*/}
                                    </button>
                                )}
                            </div>
                        )
                    }}
                </ToastBar>
            )
        }}
    </Toaster>

export default Toasty
