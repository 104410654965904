import { Dialog } from '@headlessui/react'
import {twMerge} from 'tailwind-merge'
import {AnimatePresence, motion} from 'framer-motion'

const Alert = ({ onClose, isOpen, children, className, atBottom = false}) => {
    return (
        <AnimatePresence>
            {isOpen && (
            <Dialog
                as="div"
                className="fixed z-[100]"
                onClose={onClose}
                open={isOpen}
            >
                <Dialog.Overlay
                    as={motion.div}
                    key="modal"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 0.5  }}
                    exit={{
                        opacity: 0,
                        transition: {
                            duration: 0.4,
                            ease: "easeIn"
                        },
                    }}
                    transition={{
                        duration: 0.3,
                        ease: "easeOut",
                    }}
                    className="fixed inset-0 bg-black opacity-50"
                />
                <div className="fixed inset-0">
                    <div className={twMerge("flex min-h-full justify-center",
                        atBottom ? "items-end md:items-center" : "items-center")}>

                        <Dialog.Panel
                            className={twMerge("relative md:w-2xl", className)}
                            as={motion.div}
                            key="alert"
                            initial={{ y: "100vh", scale: 0.8 }}
                            animate={{ y: 0, scale:1  }}
                            exit={{
                                y: "100vh", scale: 1,
                                transition: {
                                    duration: 0.3,
                                    scale: 0.8,
                                    ease: "easeIn"
                                },
                            }}
                            transition={{
                                duration: 0.4,
                                ease: "easeOut",
                            }}
                        >
                                {children}
                        </Dialog.Panel>
                    </div>
                </div>
            </Dialog>
            )}
        </AnimatePresence>
    )
}

export default Alert
