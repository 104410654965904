import Alert from './Alert'
import { ReactComponent as Calendar } from '../../assets/icons/calendar.svg'
import { ReactComponent as Time } from '../../assets/icons/time.svg'
import { ReactComponent as Luggage } from '../../assets/icons/indications/luggage.svg'
import { ReactComponent as Close } from '../../assets/icons/clost.svg'
import { ReactComponent as Comment } from '../../assets/icons/comment.svg'
import { UpdateWizardMode } from '../../config/constants';

const ActionsDialog = ({onClose, isOpen, onClick}) => {

    const actions = [
        {
            title: "Datum wijzigen",
            icon: <Calendar className="w-7 h-7"/>,
            operation: UpdateWizardMode.DATE
        },
        {
            title: "Tijd wijzigen",
            icon: <Time className="w-7 h-7"/>,
            operation: UpdateWizardMode.TIME
        },
        {
            title: "Reizigers & bagage wijzigen",
            icon: <Luggage className="w-7 h-7"/>,
            operation: UpdateWizardMode.ATTRIBUTES
        },
        {
            title: "Opmerkingen wijzigen",
            icon: <Comment className="w-7 h-7"/>,
            operation: UpdateWizardMode.COMMENT
        }
    ]

    const handleClick = (action) => {
        onClick(action)
        onClose()
    }

    return (
        <Alert
            onClose={onClose}
            isOpen={isOpen}
            atBottom
            className="bg-white rounded-t-[16px] md:rounded-[16px] flex flex-col items-center  w-full md:w-2/5"
        >
            <div className="flex flex-col justify-center items-center bg-white rounded-[20px] overflow-hidden w-full">
                <div className="w-full flex relative flex flex-row items-center justify-end p-3">
                    <button tabIndex={0} onClick={onClose}><Close/></button>
                </div>
                {actions.map((action, index) => (
                    <div
                        key={index}
                        className="cursor-pointer flex flex-row justify-start items-center w-full space-x-4 p-4 border-t border-grey-400 hover:bg-primary-100"
                        onClick={() => handleClick(action.operation)}
                    >
                        {action.icon}
                        <span className="text-base font-inter font-semibold text-grey-600">{action.title}</span>
                    </div>))}
            </div>

        </Alert>
    )
}

export default ActionsDialog
