import { useTranslation } from 'react-i18next'
import React, { useMemo, useState } from 'react'
import { ReactComponent as Close } from '../assets/icons/clost.svg'
import { Alert, MonthPicker } from '../components/molecules'
import PDFGenerator from '../components/organisms/PDFGenerator'
import useApi, { HTTPMethod } from '../utils/hooks/useApi'
import { BASE_URL } from '../config/constants'
import { useAccount } from '../context/AccountContext'
import { useMutation } from '@tanstack/react-query'
import { Button, States} from '../components/atoms/Button'

const TripExportForm = ({ onClose, isOpen, mode }) => {
    const { t } = useTranslation()
    const { callApi } = useApi()
    const { selectedAccount } = useAccount()
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [data, setData] = useState(null)

    const range = useMemo(() => {
        if (selectedDate && !isNaN(selectedDate)) {
            setData(null)
            const firstDay = new Date(Date.UTC(selectedDate.getUTCFullYear(), selectedDate.getUTCMonth() + 1, 1))
            const lastDay = new Date(Date.UTC(selectedDate.getUTCFullYear(), selectedDate.getUTCMonth() + 2, 1))

            const formatDateTime = (date) =>
                `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, '0')}-${String(date.getUTCDate()).padStart(2, '0')}T00:00:00+00:00`

            return {
                minimum: formatDateTime(firstDay),
                maximum: formatDateTime(lastDay)
            }
        }
    }, [selectedDate])

    const fetchTrips = async() => {
        try {
            const response = await callApi(
                "trip/loadtriptablemodels",
                {
                    filter: {
                        customerIds: [selectedAccount?.id],
                        isDeleted: false,
                        loadTotalRecordsCount: true,
                        tripStatusIds: [
                            "c09699ff-6e81-4d49-a3b6-4d53feb52c32",  // ARCHIVED
                            "9a2c8582-1d62-4424-ac66-395b41b84971", // CKO
                        ],
                        tripDate: {
                            minimum: range?.minimum,
                            maximum: range?.maximum
                        }
                    },
                    page: {
                        recordIndex: 0,
                        recordCount: 1000
                    },
                    sorts: [{ column: "pickupDateTime", direction: 1 }]
                },
                HTTPMethod.POST,
                true, BASE_URL
            )
            return response;
        } catch (error) {
            console.error("Error loading trips:", error)
        }
    }

    const { mutate } = useMutation(fetchTrips, {
        onSuccess: (data) => {
            setData(data?.records)
        },
        onError: (error) => {
            console.error("Error loading trips:", error)
        }
    })

    return (
        <Alert
            onClose={onClose}
            isOpen={isOpen}
            hideCloseButton={true}
            className="bg-white rounded-[16px] flex flex-col items-center px-2.5 pb-4 w-full md:max-w-[400px] mx-2.5"
        >
            <div className="relative border-b-grey-400 border-b flex flex-row items-center px-4 w-full">
                <h1 className="w-full text-center font-inter font-semibold text-primary-500 py-5">{t('account.button.download')}</h1>
                <button tabIndex={0} onClick={onClose} className="absolute right-4"><Close/></button>
            </div>

            <div className="flex flex-col items-start lg:justify-center relative w-full pt-7">
                <span className="font-inter font-semibold text-primary-500 pb-1 indent-4">Periode</span>
                <MonthPicker className="max-w-full" onChange={(date) => setSelectedDate(date)}/>
                {!data ?
                    <Button
                        state={States.OUTLINED}
                        className="w-full mt-7" onClick={mutate}
                    >
                        {t('account.button.retrieve')}
                    </Button> :
                <PDFGenerator
                    data={data}
                    selectedDate={selectedDate}
                    className="mt-7 bg-primary-600 text-white rounded-[12px] font-inter font-bold tracking-wide flex flex-row items-center justify-center text-sm h-[60px] w-full"
                />}
            </div>
        </Alert>
    )
}

export default TripExportForm
