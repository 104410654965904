import { motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'
import { useEffect, useState } from 'react'
import { ReactComponent as Phone } from '../../assets/icons/phone.svg'
import { ReactComponent as Error } from '../../assets/icons/error.svg';
import { ReactComponent as Check } from '../../assets/icons/check.svg'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { formatPhoneNumber } from '../../utils/helper'
import Cookies from 'js-cookie';

const ViewStates = {
    EMPTY: 'empty',
    FILLED: 'filled',
    ERROR: 'error',
}

const PhoneInputField = ({ onChange, onValid, label, ...props }) => {
    const { htmlFor, defaultValue } = props
    const [viewState, setViewState] = useState(ViewStates.EMPTY)
    const [value, setValue] = useState()
    const [valid, setValid] = useState(false)

    const style = {
        [ViewStates.EMPTY]: {
            input: 'bg-grey-200 border-grey-300 border focus:outline-primary-500',
            icon: 'bg-grey-400',
        },
        [ViewStates.FILLED]: {
            input: 'text-grey-500 focus:outline-primary-500 border-2 border-primary-500',
            icon: 'bg-primary-200',
        },
        [ViewStates.ERROR]: {
            input: 'bg-grey-200 border-secondary-300 border-2 focus:outline-secondary-300',
            icon: 'bg-secondary-300',
        }
    }

    useEffect(() => {
        if (!value) {
            setViewState(ViewStates.EMPTY)
            return
        }

        let val = !!value && isValidPhoneNumber(value, 'NL')
        if (val) {
            setViewState(ViewStates.FILLED)
            onChange && onChange(formatPhoneNumber(value))
            Cookies.set('phone', value, { expires: 7 }); // Expires in 7 days
        } else {
            setViewState(ViewStates.ERROR)
        }
        setValid(val)
        onValid && onValid(val)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    // Load phone number from default value or cookie on mount
    useEffect(() => {
        const savedPhone = Cookies.get('phone');
        if (savedPhone) {
            setValue(defaultValue || savedPhone);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (value) => {
        // Allow numbers and the + character at the beginning of the string
        const numericValue = value.replace(/^(?!\+)|[^\d+]+/g, '')
        setValue(numericValue)
    }

    const handleKeyDown = (e) => {
        // Allow numbers
        const isNumber = /\d/.test(e.key)
        // Allow '+' only if it's the first character and not already present
        const isPlus = e.key === '+' && e.target.value.length === 0 && !e.target.value.includes('+')

        if (!isNumber && !isPlus && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
            e.preventDefault()
        }
    }

    return (
        <motion.div className={twMerge("relative w-full cursor-pointer")}>
            {!!label && <label htmlFor={htmlFor} className="input-label select-none">{label}</label>}
            {/*<div className={style[viewState].className}>*/}
            <div className="relative flex flex-row items-center">
                <div className={
                    twMerge(
                        "absolute left-2 w-12 h-12 rounded-full flex items-center justify-center shrink-0 z-10",
                        style[viewState].icon
                    )}
                >
                    <Phone className="fill-primary-500"/>
                </div>
                <input
                    {...props}
                    id={htmlFor}
                    defaultValue={value}
                    type="phone"
                    autoComplete="tel"
                    pattern="^\+?\d*$" // Allow numbers and the + character at the beginning of the string
                    className={twMerge(
                        style[viewState].input,
                        "w-full px-16 py-5 rounded-full flex font-inter text-base font-semibold text-primary-500",
                    )}
                    autoFocus
                    onChange={(e) => handleChange(e.target.value)}
                    onKeyDown={handleKeyDown} // Use onKeyDown instead of onKeyPress
                />
                {viewState === ViewStates.ERROR && <Error className="fill-secondary-600 absolute right-2"/>}
                {valid && <Check className="fill-secondary-600 absolute right-2"/>}
            </div>
        </motion.div>
    )
}

export default PhoneInputField
