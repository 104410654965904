import {TripStatus} from '../../config/constants'
import {twMerge} from 'tailwind-merge'

const TimeLabel = ({ time, status, className }) => {
    const style = () => {
        switch (status) {
            case TripStatus.CONFIRM:
            case TripStatus.ACCEPT:
                return 'text-primary-500 bg-primary-100'
            case TripStatus.PLAN:
            case TripStatus.AUTOPLAN:
                return 'text-primary-500 bg-grey-200'
            // case TripStatus.ESTIMATE:
            //     return 'text-grey-500 bg-transparent'
            default:
                return 'text-primary-500 bg-transparent'
        }
    }

    return (
        <span className={
            twMerge(
                "px-4 flex h-10 text-base font-inter font-bold items-center justify-center rounded-[12px] shrink-0",
                style(status),
                className
            )}
        >
            {time}
        </span>
    )
}

export default TimeLabel
