import {ReactComponent as ArrowLeft} from '../../assets/icons/chevron-l.svg'
import {ReactComponent as ArrowRight} from '../../assets/icons/chevron-r.svg'
import {useEffect, useState} from 'react'
import {capitalizeFirstLetter} from '../../utils/helper'
import {twMerge} from 'tailwind-merge'

const MonthPicker = ({ onChange, className }) => {
    const [currentDate, setCurrentDate] = useState(new Date())

    useEffect(() => {
        onChange && onChange(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDate])

    function decrementMonth() {
        setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)))
    }

    function incrementMonth() {
        setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)))
    }

    const isPresent = () => {
        const date = new Date()
        return currentDate.getFullYear() === date.getFullYear() && currentDate.getMonth() === date.getMonth()
    }

    return (
        <div className={twMerge("w-full max-w-[350px] flex justify-between py-5 px-6 rounded-full border-2 " +
            "border-grey-400 focus:border-primary-500 bg-white focus:ring-0 focus:outline-none",
            className)}>
            <ArrowLeft tabIndex={0} className="w-6 h-6 fill-primary-500" onClick={decrementMonth}/>
            <span className="font-inte font-semibold text-primary-500 text-base">
                {capitalizeFirstLetter(currentDate?.toLocaleString('nl',
                    { month: 'long' }))} {currentDate?.getFullYear()}
            </span>
            <ArrowRight tabIndex={0} className={twMerge("w-6 h-6", !isPresent()
                ? "fill-primary-500" : "fill-grey-400")} onClick={() => !isPresent() && incrementMonth()}/>
        </div>
    )
}

export default MonthPicker
