import { twMerge } from 'tailwind-merge'
import { ReactComponent as Arrow } from '../../assets/icons/arrow-l.svg'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const AuthForm = ({title, body, icon, children, className, showBack}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <div className={twMerge('bg-white rounded-[16px] px-2.5 pb-2.5 mb-5', showBack ? 'pt-5' : 'pt-8', className)}>
            {!!showBack && <div className="flex flex-row items-center space-x-2" onClick={() => navigate(-1)}>
                <Arrow className="fill-primary-400"/>
                <span className="font-inter text-sm font-semibold text-primary-400">{t('navigation.back')}</span>
            </div>}
            <div className="flex flex-col justify-center items-center space-y-2 mb-8 px-6">
                {!!icon && <div className="flex items-center justify-center mb-4 bg-primary-200 w-12 h-12 rounded-full">{icon}</div>}
                <h1 className="text-primary-500 font-inter font-semibold text-2xl shrink-0 text-center">{title}</h1>
                <span className="text-black font-inter font-normal text-base text-center block">
                     <Trans i18nKey={body}/>
                </span>
            </div>
            {children}
        </div>
    )
}

export default AuthForm
