import React from 'react'
import { Document, Page, Text, View, PDFDownloadLink, Image, Font, StyleSheet } from '@react-pdf/renderer'
import logo from '../../assets/img/logo.png'
import {useAccount} from '../../context/AccountContext'
import {adjustForDutchTimezone} from '../../utils/helper';
import {format} from 'date-fns';

// Register font
Font.register({
    family: 'Inter',
    fonts: [
        {
            src: '../fonts/Inter-Regular.ttf',
            fontWeight: 'normal',
        },
        {
            src: '../fonts/Inter-Bold.ttf',
            fontWeight: 'bold',
        },
        {
            src: '../fonts/Inter-Italic.ttf',
            fontWeight: 'italic',
        },
        {
            src: '../fonts/Inter-SemiBold.ttf',
            fontWeight: 'semibold',
        },
    ],
})

// Define styles for the PDF
const styles = StyleSheet.create({
    page: {
        padding: 30,
        fontFamily: 'Inter',
        fontSize: 12,
        color: '#333',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
    },
    headerLeft: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    headerRight: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingTop: 20,
    },
    headerTitle: {
        fontSize: 14,
        color: '#009CB4',
        fontWeight: 'semibold',
        paddingBottom: 10,
    },
    headerSubTitle: {
        fontSize: 10,
        color: '#333',
        fontWeight: 'semibold',
        paddingRight: 5,
    },
    row: {
        fontSize: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 5,
        fontWeight: 'normal',
        color: '#333',
    },
    address: {
        fontSize: 10,
        color: '#333',
        fontWeight: 'normal',
        marginBottom: 24,
        lineHeight: 1.3,
    },
    name: {
        fontSize: 12,
        fontWeight: 'semibold',
        color: '#009CB4',
        marginBottom: 1.7,
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderColor: '#fff',
        borderWidth: 1,
        marginBottom: 20,
        borderBottomWidth: 2,
        borderBottomColor: '#009CB4',
    },
    tableRow: {
        flexDirection: 'row',
        alignItems: 'center',
        color: '#333',
        borderBottomWidth: 1,
        borderBottomColor: '#DEDEDE',
        borderBottomStyle: 'solid',
        fontSize: 10,
        paddingVertical: 15,
    },
    tableHeader: {
        backgroundColor: '#fff',
        fontWeight: 'semibold',
        borderBottomWidth: 2,
        borderBottomColor: '#009CB4',
        borderTopColor: '#fff',
        paddingBottom: 10,
    },
    tableCell: {
        flex: 1,
        paddingRight: 10,
    },
    tableCellEnd: {
        flex: 1,
        textAlign: 'right',
    },
    tableCellLarge: {
        flex: 2,
        paddingRight: 10,
    },
    totalRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 4,
        width: '100%',
        fontWeight: 'semibold',
        color: '#009CB4',
    },
    totalLabel: {
        fontSize: 12,
    },
    totalBody: {
        fontSize: 10,
        paddingRight: 0,
    },
    footer: {
        marginTop: 20,
        fontSize: 10,
        fontWeight: 'normal',
        color: '#00',
        position: 'absolute',
        bottom: 40,
        left: 30,
    },
    footerQuestion: {
        fontWeight: 'semibold',
        fontSize: 12,
        paddingBottom: 5,
    },
    footerText: {
        lineHeight: 1.5,
    }
})

const monthNames = [
    'januari', 'februari', 'maart',
    'april', 'mei', 'juni', 'juli',
    'augustus', 'september', 'oktober',
    'november', 'december'
]

/**
 * Retrieves the pass number of the first valid subcontract for a given date.
 * @param {Object} account - The account object containing customer subcontracts.
 * @param {Date} date - The date to check for valid subcontracts.
 * @returns {string|undefined} - The pass number of the first valid subcontract, or undefined if none found.
 */
function getPassNumberOnDate(account, date) {
    if (!account || !account.customerSubcontracts) {
        return undefined;
    }

    const validSubcontract = account.customerSubcontracts.find(subcontract => {
        if (!subcontract.validFromDateTime && !subcontract.validToDateTime) {
            return subcontract;
        }

        const validFrom = new Date(subcontract?.validFromDateTime);
        const validTo = new Date(subcontract?.validToDateTime);

        return validFrom <= date && validTo >= date;
    });

    return validSubcontract?.passNumber;
}

function formatPickupDateTime(dateTime) {
    return format(adjustForDutchTimezone(dateTime), 'HH:mm')//date.toLocaleDateString('nl-NL', options)
}

const PDFReport = ({ records, date, account }) => {

    const distance = (record) => record?.tripTariffs ? record?.tripTariffs[0].metersInner / 1000 : 0
    const price = (record) => record?.tripTariffs ? record?.tripTariffs[0].totalPrice : 0

    const totalKM = records.reduce((sum, record) => sum + distance(record), 0)
    const totalPrice = records.reduce((sum, record) => sum + price(record), 0)
    const address = account?.locations ? {
        street: `${account?.locations[0]?.location?.street} ${account?.locations[0]?.location?.houseNumber}`,
        zip: account?.locations[0]?.location?.zip,
        city: account?.locations[0]?.location?.place,
    } : {
        street: '',
        zip: '',
        city: '',
    }

    return (
        <Document>
            <Page style={styles.page}>
                <View style={styles.header}>
                    <View style={styles.headerLeft}>
                        <Image src={logo} style={{ width: 130, height: 20 }} />
                    </View>
                    <View style={styles.headerRight}>
                        <Text style={styles.headerTitle}>Rittenoverzicht</Text>
                        <View style={styles.row}>
                            <Text style={styles.headerSubTitle}>Periode:</Text>
                            <Text>{`${monthNames[date.getMonth()]} ${date.getFullYear()}`}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.headerSubTitle}>Pasnummer:</Text>
                            <Text>{getPassNumberOnDate(account, date)}</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.address}>
                    <Text style={styles.name}>{account?.fullName}</Text>
                    <Text>{address.street}</Text>
                    <Text>{`${address.zip} ${address.city}`}</Text>
                </View>
                <View style={styles.table}>
                    <View style={[styles.tableRow, styles.tableHeader]}>
                        <Text style={styles.tableCell}>Datum</Text>
                        <Text style={styles.tableCell}>Vertrek</Text>
                        <Text style={styles.tableCell}>Aankomst</Text>
                        <Text style={styles.tableCellLarge}>Van</Text>
                        <Text style={styles.tableCellLarge}>Naar</Text>
                        <Text style={styles.tableCell}>KM</Text>
                        <Text style={styles.tableCellEnd}>Prijs</Text>
                    </View>
                    {records.map((record, index) => {
                        // change date format to locale string in correct timezone and in format HH:MM
                        return (
                            <View style={styles.tableRow} key={index}>
                                <Text style={styles.tableCell}>{new Date(record?.pickupDateTime).toLocaleDateString()}</Text>
                                <Text style={styles.tableCell}>{record?.tripOperationTimes?.realPickupTime ? formatPickupDateTime(record?.tripOperationTimes?.realPickupTime) : ""}</Text>
                                <Text style={styles.tableCell}>{record?.tripOperationTimes?.deliveryDateTime ? formatPickupDateTime(record?.tripOperationTimes?.deliveryDateTime) : ""}</Text>
                                <Text style={styles.tableCellLarge}>{record?.locationFrom.address}</Text>
                                <Text style={styles.tableCellLarge}>{record?.locationTo.address}</Text>
                                <Text style={styles.tableCell}>
                                    {distance(record).toFixed(2).replace('.', ',')} KM
                                </Text>
                                <Text style={styles.tableCellEnd}>€ {price(record).toFixed(2).replace('.', ',')}</Text>
                            </View>
                        )
                    })}
                </View>
                <View style={styles.totalRow}>
                    <Text style={styles.totalLabel}>Totaal</Text>
                    <Text style={styles.totalBody}>{totalKM.toFixed(2).replace('.', ',')} KM       € {totalPrice.toFixed(2)}</Text>
                </View>
                <View style={styles.footer}>
                    <Text style={styles.footerQuestion}>Vragen?</Text>
                    <Text style={styles.footerText}>Mocht u nog vragen hebben of willen reageren op dit overzicht dan zijn wij bereikbaar via info@plusov.nl of telefoonnummer 088 758 7654.</Text>
                </View>
            </Page>
        </Document>
    )
}

const PDFGenerator = ({ data, selectedDate, className }) => {
    const { selectedAccount } = useAccount()
    return (
        <PDFDownloadLink
            className={className}
            document={data && <PDFReport records={data} date={selectedDate} account={selectedAccount}/>}
            fileName={`Rittenoverzicht_${monthNames[selectedDate.getMonth()]}_${selectedDate.getYear() - 100}.pdf`}
        >
            {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download PDF')}
        </PDFDownloadLink>
    )
}

export default PDFGenerator;
