import { adjustForDutchTimezone, capitalizeFirstLetter } from '../../utils/helper'
import { Badge } from './index'
import { TripStatus } from '../../config/constants'

const TripHeader = ({ timestamp, status }) => {
    const renderTimestamp = (date) => {
        const now = new Date()
        const tomorrow = new Date(now)
        tomorrow.setDate(now.getDate() + 1)

        switch (date.toDateString()) {
            case now.toDateString():
                return "Vandaag"
            case tomorrow.toDateString():
                return "Morgen"
            default:
                const options = { year: 'numeric', month: 'short', day: '2-digit', weekday: 'long' }
                return capitalizeFirstLetter(date.toLocaleDateString('nl-NL', options))
        }
    }

    const d = adjustForDutchTimezone(timestamp)
    const title = renderTimestamp(d)

    return (
        <div className="flex flex-row justify-between px-3">
            <h2 className="font-inter text-xl mb-2 text-grey-600 font-semibold">{title}</h2>
            {status === TripStatus.CONFIRM && <Badge badge="Actief"/>}
            {/*{status === TripStatus.CANCEL && <Badge badge="Geannuleerd" className="bg-primary-500 text-white"/>}*/}
        </div>
    )
}

export default TripHeader
