import { motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { ReactComponent as Time } from '../../assets/icons/time.svg'
import { ReactComponent as Error } from '../../assets/icons/error.svg'
import '../../timePicker.css'
import '../../clock.css'
import DatePicker from 'react-datepicker'
import { registerLocale } from  "react-datepicker"
import nl from 'date-fns/locale/nl'
import '../../picker.css'

registerLocale('nl', nl)

const ViewStates = {
    EMPTY: 'empty',
    FILLED: 'filled',
}

const TimePickerField = forwardRef(({ values = null, onChange, defaultValue, label, ...props }, ref) => {
    const { className, htmlFor, min, max, hasError, placeholder } = props
    const [viewState, setViewState] = useState(ViewStates.EMPTY)
    const [value, setValue] = useState(defaultValue || "")

    const inputRef = useRef(null)
    useImperativeHandle(ref, () => inputRef.current)

    const content = {
        [ViewStates.EMPTY]: {
            style:
                twMerge(
                    "address-item",
                    'bg-grey-200 border-grey-300 border overflow-hidden relative',
                    className
                ),
            icon: "bg-grey-400 text-grey-500",
        },
        [ViewStates.FILLED]: {
            style:
                twMerge(
                    "address-item",
                    'text-grey-500 overflow-hidden relative',
                    className
                ),
            icon: "bg-primary-200",
        },
        [ViewStates.ERROR]: {
            style:
                twMerge(
                    "address-item",
                    'bg-grey-200 border-secondary-300 border-2 focus:outline-secondary-300 text-grey-500 overflow-hidden relative',
                    className
                ),
            icon: "bg-secondary-300"
        }
    }

    useEffect(() => {
        if (value) {
            setViewState(ViewStates.FILLED)
            onChange(value)
        }
        if (hasError) {
            setViewState(ViewStates.ERROR)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, hasError])

    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <span className="px-2 flex flex-row w-full items-center font-inter font-semibold text-base text-primary-500" onClick={onClick} ref={ref}>
            {value ? value : "Selecteer een tijd"}
        </span>
    ))

    const tomorrow = () => {
        const date = new Date()
        date.setDate(date.getDate() + 1)
        date.setHours(0, 0, 0, 0)
        return date
    }

    return (
        <motion.div ref={inputRef} className={twMerge("relative w-full cursor-pointer")}>

            {!!label && <label htmlFor={htmlFor} className="input-label select-none">{label}</label>}
            <div className={content[viewState].style}>
                <div className={
                    twMerge(
                        "w-12 h-12 rounded-full flex items-center justify-center shrink-0",
                        content[viewState].icon
                    )}
                >
                    <Time/>
                </div>
                <DatePicker
                    selected={value}
                    openToDate={tomorrow()}
                    minDate={tomorrow()}
                    onChange={(date) => setValue(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={5}
                    minTime={min}
                    maxTime={max}
                    locale="nl"
                    dateFormat="HH:mm"
                    withPortal
                    placeholderText={placeholder}
                    customInput={<CustomInput />}
                    dropdownMode="select"
                    selectsStart
                />
                {viewState === ViewStates.ERROR && <Error className="fill-secondary-600 absolute right-2"/>}
            </div>
        </motion.div>
    )
})

export default TimePickerField
