import React from 'react'

import { ReactComponent as ACT} from '../../assets/icons/trip/activity.svg'
import { ReactComponent as APO} from '../../assets/icons/trip/hospital.svg'
import { ReactComponent as ART} from '../../assets/icons/trip/doctor.svg'
import { ReactComponent as AVO} from '../../assets/icons/trip/school.svg'
import { ReactComponent as BED} from '../../assets/icons/trip/company.svg'
import { ReactComponent as BGP} from '../../assets/icons/trip/funeral.svg'
import { ReactComponent as BIB} from '../../assets/icons/trip/school.svg'
import { ReactComponent as BIO} from '../../assets/icons/trip/ticket.svg'
import { ReactComponent as BNK} from '../../assets/icons/trip/bank.svg'
import { ReactComponent as BRT} from '../../assets/icons/trip/activity.svg'
import { ReactComponent as BRW} from '../../assets/icons/trip/municipality.svg'
import { ReactComponent as BSO} from '../../assets/icons/trip/bso.svg'
import { ReactComponent as CAF} from '../../assets/icons/trip/drink.svg'
import { ReactComponent as CAR} from '../../assets/icons/trip/company.svg'
import { ReactComponent as CFT} from '../../assets/icons/trip/restaurant.svg'
import { ReactComponent as CMP} from '../../assets/icons/trip/camping.svg'
import { ReactComponent as CON} from '../../assets/icons/trip/company.svg'
import { ReactComponent as DGV} from '../../assets/icons/trip/activity.svg'
import { ReactComponent as DHT} from '../../assets/icons/trip/street.svg'
import { ReactComponent as DIV} from '../../assets/icons/trip/street.svg'
import { ReactComponent as DOK} from '../../assets/icons/trip/doctor.svg'
import { ReactComponent as DT} from '../../assets/icons/trip/ticket.svg'
import { ReactComponent as FLT} from '../../assets/icons/trip/building.svg'
import { ReactComponent as FYS} from '../../assets/icons/trip/sport.svg'
import { ReactComponent as GEM} from '../../assets/icons/trip/municipality.svg'
import { ReactComponent as GVT} from '../../assets/icons/trip/street.svg'
import { ReactComponent as GZC} from '../../assets/icons/trip/hospital.svg'
import { ReactComponent as HH} from '../../assets/icons/trip/stop.svg'
import { ReactComponent as HOT} from '../../assets/icons/trip/company.svg'
import { ReactComponent as KAP} from '../../assets/icons/trip/barber.svg'
import { ReactComponent as KDV} from '../../assets/icons/trip/bso.svg'
import { ReactComponent as KRK} from '../../assets/icons/trip/church.svg'
import { ReactComponent as MUS} from '../../assets/icons/trip/company.svg'
import { ReactComponent as NS} from '../../assets/icons/trip/station.svg'
import { ReactComponent as OIM} from '../../assets/icons/trip/hospital.svg'
import { ReactComponent as PAV} from '../../assets/icons/trip/company.svg'
import { ReactComponent as PEN} from '../../assets/icons/trip/company.svg'
import { ReactComponent as POL} from '../../assets/icons/trip/municipality.svg'
import { ReactComponent as POS} from '../../assets/icons/trip/street.svg'
import { ReactComponent as PP} from '../../assets/icons/trip/ticket.svg'
import { ReactComponent as PSY} from '../../assets/icons/trip/street.svg'
import { ReactComponent as PZH} from '../../assets/icons/trip/hospital.svg'
import { ReactComponent as REV} from '../../assets/icons/trip/sport.svg'
import { ReactComponent as RST} from '../../assets/icons/trip/restaurant.svg'
import { ReactComponent as SCH} from '../../assets/icons/trip/school.svg'
import { ReactComponent as SPO} from '../../assets/icons/trip/sport.svg'
import { ReactComponent as SYN} from '../../assets/icons/trip/street.svg'
import { ReactComponent as TAN} from '../../assets/icons/trip/dentist.svg'
import { ReactComponent as THT} from '../../assets/icons/trip/street.svg'
import { ReactComponent as UIT} from '../../assets/icons/trip/funeral.svg'
import { ReactComponent as WKC} from '../../assets/icons/trip/shop.svg'
import { ReactComponent as WNK} from '../../assets/icons/trip/shop.svg'
import { ReactComponent as WVM} from '../../assets/icons/trip/building.svg'
import { ReactComponent as ZAL} from '../../assets/icons/trip/company.svg'
import { ReactComponent as ZGC} from '../../assets/icons/trip/hospital.svg'
import { ReactComponent as ZKH} from '../../assets/icons/trip/hospital.svg'
import { ReactComponent as ZWB} from '../../assets/icons/trip/pool.svg'
import { ReactComponent as HOS} from '../../assets/icons/trip/hospital.svg'
import { ReactComponent as CAS} from '../../assets/icons/trip/activity.svg'
import { ReactComponent as KLO} from '../../assets/icons/trip/trift.svg'
import { ReactComponent as BOW} from '../../assets/icons/trip/street.svg'
import { ReactComponent as HOME} from '../../assets/icons/trip/home.svg'
import { ReactComponent as AZC} from '../../assets/icons/trip/street.svg'


//import * as PoiIcons from '../../assets/icons/trip'

const iconMappings = {
    ACT,
    APO,
    ART,
    AVO,
    BED,
    BGP,
    BIB,
    BIO,
    BNK,
    BRT,
    BRW,
    BSO,
    CAF,
    CAR,
    CFT,
    CMP,
    CON,
    DGV,
    DHT,
    DIV,
    DOK,
    DT,
    FLT,
    FYS,
    GEM,
    GVT,
    GZC,
    HH,
    HOT,
    KAP,
    KDV,
    KRK,
    MUS,
    NS,
    OIM,
    PAV,
    PEN,
    POL,
    POS,
    PP,
    PSY,
    PZH,
    REV,
    RST,
    SCH,
    SPO,
    SYN,
    TAN,
    THT,
    UIT,
    WKC,
    WNK,
    WVM,
    ZAL,
    ZGC,
    ZKH,
    ZWB,
    HOS,
    CAS,
    KLO,
    BOW,
    HOME,
    AZC,
}

const Poi = ({ acronym }) => {
    const IconComponent = iconMappings[!!acronym ? acronym.toUpperCase() : POS]

    if (!IconComponent) {
        // Return a default or empty icon if acronym is not recognized
        return <></>
    }

    return <IconComponent className="w-7 h-7 shrink-0" />
};

export default Poi
