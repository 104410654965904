import { motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'
import {forwardRef, useEffect, useState} from 'react'
import { ReactComponent as Calendar } from '../../assets/icons/calendar.svg'
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron-l.svg'
import { ReactComponent as ChevronRight } from '../../assets/icons/chevron-r.svg'
import { format, parse } from 'date-fns'
import { nl } from 'date-fns/locale'

import DatePicker from "react-datepicker"
import '../../picker.css'
import {capitalizeFirstLetter, isToday, addHoursToDate, resetTime} from '../../utils/helper'

const ViewStates = {
    EMPTY: 'empty',
    FILLED: 'filled',
}

const DatePickerField = ({ onChange, defaultValue, label, ...props }) => {
    const { className, htmlFor } = props
    const [value, setValue] = useState(defaultValue || null)
    const [viewState, setViewState] = useState(ViewStates.EMPTY)

    const content = {
        [ViewStates.EMPTY]: {
            style:
                twMerge(
                    "address-item",
                    'bg-grey-200 border-grey-300 border overflow-hidden relative',
                    className
                )
        },
        [ViewStates.FILLED]: {
            style:
                twMerge(
                    "address-item",
                    'bg-grey text-grey-500 overflow-hidden relative',
                    className
                )
        },
    }

    useEffect(() => {
        if (value) {
            setViewState(ViewStates.FILLED)
            onChange(value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <span className="px-2 flex flex-row w-full items-center font-inter font-semibold text-base text-primary-500" onClick={onClick} ref={ref}>
            {value === convertToString(new Date()) ? 'Vandaag' : !!value && capitalizeFirstLetter(convertToDateString(value))}
        </span>
    ))

    // convert date to string in format dd/MM/y
    const convertToString = (date) => {
        const day = date.getDate()
        const month = date.getMonth() + 1
        const year = date.getFullYear()
        return `${day}/${month < 10 ? '0' + month : month}/${year}`
    }

    const convertToDateString = (dateString) => {
        // Parse the date string into a Date object
        const parsedDate = parse(dateString, 'dd/MM/y', new Date())

        // Format the Date object
        return format(parsedDate, 'EEEE d MMMM', { locale: nl })
    }

    return (
        <motion.div className={twMerge("relative w-full cursor-pointer")}>
            {!!label && <label htmlFor={htmlFor} className="input-label select-none">{label}</label>}
            <div className={content[viewState].style}>
                <div className={
                    twMerge(
                        "w-12 h-12 rounded-full flex items-center justify-center shrink-0",
                        viewState === ViewStates.FILLED ? 'bg-primary-200 text-white' : 'bg-grey-400 text-grey-500'
                    )}
                >
                    <Calendar/>
                </div>
                <DatePicker
                    locale={nl}
                    className="w-full"
                    minDate={new Date()}
                    onChange={(date) => {
                        isToday(date) ? setValue(addHoursToDate(new Date(), 1)) : setValue(resetTime(date))
                    }}
                    renderCustomHeader={({
                        date,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled
                    }) => (
                        <div className="flex justify-between items-center px-4 py-5 border-b border-grey-300">
                            <button tabIndex={-1} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                <ChevronLeft className="fill-primary-500"/>
                            </button>
                            <span className="text-primary-500 font-inter text-base font-semibold flex flex-row"><Calendar className="mr-3"/>{date.toLocaleString('nl-NL', { month: 'long', year: 'numeric' })}</span>
                            <button tabIndex={-1} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                <ChevronRight className="fill-primary-500"/>
                            </button>
                        </div>
                    )}
                    customInput={<ExampleCustomInput />}
                    selected={value}
                    dateFormat="dd/MM/y"
                    withPortal
                />
            </div>
        </motion.div>
    )
}

export default DatePickerField
