import { parsePhoneNumber } from 'libphonenumber-js'
import { TripStatus } from '../config/constants'

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

function isArchived(trip) {
    const status = trip?.tripOperation?.tripStatusType?.name
    return status === TripStatus.ARCHIVED
        || status === TripStatus.CANCEL
        || status === TripStatus.NOSHOW
}

function generateGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
}

/**
 * Helper retrieve trip status from trip
 *
 * @param trip
 * @returns {*}
 */
const getTripStatus = (trip) => trip?.tripOperation?.tripStatusType?.name

const adjustForDutchTimezone = (timestamp) => (
    // If there's no 'Z' in the original timestamp (indicating it's in UTC), adjust for Dutch timezone
    new Date((!/[Zz]$/.test(timestamp)) ? `${timestamp}Z` : timestamp)
)

const formatDate = (date) => {
    if (!date) return ''
    const d = new Date(date)
    const options = { month:"long", day: "2-digit", weekday: "long"}
    return d.toLocaleDateString("nl-NL", options)
}

const formatPhoneNumber = value => parsePhoneNumber(value, 'NL').number

function isToday(dateToCheck) {
    return dateToCheck.toLocaleDateString() === new Date().toLocaleDateString();
}

function addHoursToDate(date, hoursToAdd) {
    const newDate = new Date(date);
    newDate.setHours(newDate.getHours() + hoursToAdd);
    return newDate;
}

function resetTime(date) {
    date.setHours(0, 0, 0, 0);
    return date;
}

const locationModel = () => (
    {
        "address": "",
        "countryCode": "",
        "provinceId": null,
        "provinceName": "",
        "municipalityId": null,
        "municipalityName": "",
        "street": "",
        "place": "",
        "poiName": null,
        "poiDescription": null,
        "poiTypeCode": null,
        "poiTypeName": null,
        "poiTypeIcon": null,
        "houseNumber": "",
        "zip": "",
        "latitude": null,
        "longitude": null,
        "additional": null,
        "additionalPrivate": null,
        "isDeleted": false
    }
)

export {
    capitalizeFirstLetter,
    isArchived,
    formatPhoneNumber,
    generateGUID,
    getTripStatus,
    formatDate,
    adjustForDutchTimezone,
    isToday,
    addHoursToDate,
    resetTime,
    locationModel
}
