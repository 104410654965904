import { ValueLabel, Poi, TimeLabel } from '../atoms'
import { format } from 'date-fns'
import { twMerge } from 'tailwind-merge'
import {adjustForDutchTimezone, getTripStatus} from '../../utils/helper'

const AddressCell = ({ trip, isReturn, className }) => {

    const completed = trip?.tripOperation?.tripStatusType?.name === "ARCHIVED" || trip?.tripOperation?.tripStatusType?.name === "CKO"

    const getTime = () => {
        if (completed) {
            return !isReturn ? trip?.tripOperationTimes?.realPickupTime : trip?.tripOperationTimes?.realDeliveryTime
        } else {
            return !isReturn ? trip?.pickupDateTime : trip?.returnDateTime
        }
    }

    const status = getTripStatus(trip)

    const buildLocationInfo = (location) => {
        const { poiName, street, houseNumber, place, poiTypeCode } = location || {};
        return {
            name: poiName || `${street} ${houseNumber}`,
            subtitle: place,
            poi: poiTypeCode || 'POS',
        };
    };

    const fromLocationInfo = buildLocationInfo(isReturn ? trip?.locationTo : trip?.locationFrom)
    const toLocationInfo = buildLocationInfo(isReturn ? trip?.locationFrom : trip?.locationTo)

    return (
        <div className={twMerge("flex flex-row p-6 pr-0 bg-white", className)}>
            <div className="flex flex-col items-center space-y-2 py-1.5">
                <Poi acronym={fromLocationInfo.poi} />
                <div className="flex-1 w-[1px] bg-primary-500 my-4" />
                <Poi acronym={toLocationInfo.poi} />
            </div>
            <div className="flex flex-col flex-1">
                <div className="flex flex-row flex-1 items-center">
                    <ValueLabel title={fromLocationInfo.name} subtitle={fromLocationInfo.subtitle} className="px-4 w-[200px] flex-1"/>
                    {getTime() &&
                        <TimeLabel
                            className="mr-4"
                            time={format(adjustForDutchTimezone(getTime(trip?.pickupDateTime, trip?.returnDateTime)), 'HH:mm')}
                            status={status}
                        />}
                </div>
                <div className="w-full h-[1px] bg-grey-400 my-4" />
                <div className="flex flex-row flex-1 items-center">
                    <ValueLabel title={toLocationInfo.name} subtitle={toLocationInfo.subtitle} className="px-4 w-[200px] flex-1"/>
                    {!!trip?.deliveryDateTime &&
                        <TimeLabel
                            className="mr-4"
                            time={format(adjustForDutchTimezone(trip?.deliveryDateTime), 'HH:mm')}
                            status={status}
                        />}
                </div>
            </div>
        </div>
    )
}

export default AddressCell
