import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { InputField, AuthForm } from '../molecules'
import { Button, States } from '../atoms/Button'
import { ReactComponent as Lock } from '../../assets/icons/lock.svg'
import { useState } from 'react'
import {EMAIL_REGEX} from '../../config/constants';

const ViewStates = {
    EMPTY: 'active',
}

const SignUpForm = () => {
    const { t } = useTranslation()
    const [viewState] = useState(ViewStates.EMPTY)

    const content = {
        [ViewStates.EMPTY]: {
            title: t('sign_up.title'),
            body: t('sign_up.body'),
            icon: <Lock className="w-8 h-8" />,
        }
    }

    const schema = Yup.object().shape({
        email: Yup.string()
            .matches(
                EMAIL_REGEX,
                t('error.email.invalid')
            )
            .required(t('error.email.required'))
    })

    return (
        <AuthForm
            className="mt-12 mx-2.5 mb-6"
            title={content[viewState].title}
            body={content[viewState].body}
        >
            {
                viewState !== ViewStates.MAIL_SENT &&
                    <Formik
                        initialValues={{
                            email: '',
                        }}
                        onSubmit={({ email }) => {
                            alert(JSON.stringify(email))
                        }}
                        validationSchema={schema}
                        validateOnBlur={true}
                        validateOnChange={false}
                    >
                        {
                            ({ isValid, dirty }) =>
                                <Form className="space-y-9">
                                    <InputField
                                        name="email"
                                        placeholder={t('sign_up.form.email.placeholder')}
                                        label={t('sign_up.form.email.label')}
                                        type="email"
                                        autoComplete="email"
                                    />
                                    <div className="flex flex-row items-center justify-center w-full">
                                        <Button
                                            state={( !isValid || !dirty) ? States.DISABLED : States.DEFAULT}
                                            type="submit"
                                            className="w-full md:w-[350px]">{t('confirm.button.send')}</Button>
                                    </div>
                                </Form>
                        }
                    </Formik>
            }
        </AuthForm>
    )
}

export default SignUpForm
