import BookForm from '../components/organisms/BookForm'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Logo } from '../assets/logo/concession.svg'
import { ReactComponent as Calendar } from '../assets/icons/calendar.svg'
import { ReactComponent as Lock } from '../assets/icons/lock.svg'
import { twMerge } from 'tailwind-merge'
import {AddressCell, DialogConfirm, Tile} from '../components/molecules'
import { useNavigate } from 'react-router-dom'
import { useTrips } from '../context/TripContext'
import {EmptyMessage, Loader} from '../components/atoms'
import { useAccount } from '../context/AccountContext'
import useModal from '../utils/hooks/useModal';
import {useMsal} from '@azure/msal-react';
import NoAccountMessage from '../components/organisms/NoAccountMessage';

const Counter = ({ count }) => (
    <div className={twMerge("flex flex-col items-center justify-center w-10 h-10 rounded-[12px]",
        count > 0 ? 'bg-secondary-300 text-primary-500' : 'bg-grey-300')}>
        <span className={twMerge("text-lg font-semibold", count > 0 ? "text-primary-500" : "text-grey-500")}>{count}</span>
    </div>
)

const TodaysTrip = ({trip}) =>
    <div className={twMerge("hidden lg:flex flex-col w-1/2 tile", "px-0 pt-0  lg:h-auto lg:min-h-[243px]")}>
        <div className="flex flex-row items-center justify-between w-full border-b border-b-grey-400 h-[60px] shrink-0 px-7">
            <h2 className="font-inter text-base font-semibold text-primary-500 text-xl">Vandaag</h2>
        </div>
        {trip
            ? <AddressCell trip={{...trip}} completed={false} className={"flex flex-row w-full p-6 space-x-3 border-0 overflow-hidden"}/>
            : <EmptyMessage className="pt-10" title={"Geen ritten vandaag"} message={"Je hebt vandaag geen ritten gepland staan."}/>}
    </div>

const Book = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { planned, archived } = useTrips()
    const { accounts, selectedAccount, isLoading } = useAccount()
    const {isOpen, closeModal} = useModal()
    const {instance} = useMsal()

    const todaysTrip = planned?.trips?.find(trip => {
        const today = new Date()
        const tripDate = new Date(trip.pickupDateTime)
        return (today.getDate() === tripDate.getDate() &&
            today.getMonth() === tripDate.getMonth() &&
            today.getFullYear() === tripDate.getFullYear())
    })

    const name = selectedAccount?.firstName || selectedAccount?.fullName || selectedAccount?.lastName

    if (isLoading) return <Loader/>

    return (
        <div className="px-2.5 relative">
            {accounts.length > 0 && <div className="absolute top-0 left-0 w-full h-[64px] bg-primary-500 z-0"/>}
            <div className="w-full md:w-full md:min-w-[620px] md:px-[60px] lg:px-0 lg:w-full lg:max-w-[933px] m-auto">
                {accounts.length > 0 ?
                    <div
                        className="relative w-full flex flex-col border border-grey-400 bg-white rounded-[20px] z-10 px-2.5 md:px-[60px] lg:px-11 pb-2.5 md:pb-14 lg:pb-11 mb-5 md:mb-10">
                        <div
                            className={"flex flex-col items-center justify-center md:items-start md:justify-start w-full py-5 md:py-10 md:pb-7"}>
                            <Logo className="visible md:hidden" aria-label="logo"/>
                            {selectedAccount?.firstName &&
                                <h1 className="hidden md:block font-semibold font-inter text-primary-500 text-3xl">{t('booking.title_user', {name: name})}</h1>}
                        </div>
                        <BookForm/>
                    </div> : <NoAccountMessage/>
                    }

                {accounts?.length > 0 &&
                    <div className="flex flex-row lg:space-x-10 h-[200px] lg:h-auto lg:min-h-[243px]">
                        <TodaysTrip trip={todaysTrip}/>
                        <div className="flex flex-row w-full lg:w-1/2 space-x-5 md:space-x-10">
                            <Tile
                                title={t('trips.planned.title')}
                                body={`trips.planned.${planned?.trips?.length > 0 ? 'filled' + (planned?.trips?.length > 1 ? '_plural' : '') : 'empty'}`}
                                icon={<Counter count={planned?.total}/>}
                                onClick={() => navigate('/mijn-ritten/gepland')}
                                values={{count: planned?.total}}
                                tabIndex={0}
                            />
                            <Tile
                                title={t('trips.history.title')}
                                body={`trips.history.${archived?.trips?.length > 0 ? 'archived' : 'empty'}`}
                                icon={<div className="p-2 bg-primary-100 rounded-[10px]"><Calendar className="w-6 h-6"/></div>}
                                onClick={() => navigate('/mijn-ritten/rithistorie')}
                                tabIndex={0}
                            />
                        </div>
                    </div>}
            </div>

            <DialogConfirm
                icon={<div className="w-12 h-12 bg-primary-200 flex items-center justify-center rounded-full">
                    <Lock className=""/>
                </div>}
                isOpen={isOpen}
                onClose={closeModal}
                onConfirm={() => instance.logout()}
                title={t('dialog.logout.title')}
                body={t('dialog.logout.body')}
                confirm={t('dialog.logout.button.confirm')}
                cancel={t('dialog.logout.button.cancel')}
            />
        </div>
    )
}

export default Book
