import { NavLink } from 'react-router-dom'
import { ReactComponent as CarIcon } from '../../assets/icons/car.svg'
import { ReactComponent as TripIcon } from '../../assets/icons/trip.svg'
import { ReactComponent as ProfileIcon } from '../../assets/icons/account.svg'
import { Badge } from '../atoms'
import { useTrips } from '../../context/TripContext'
import { useAccount } from '../../context/AccountContext'

const NavItem = ({title, icon, path, badge = null}) =>
    <NavLink
        to={path}
        className={({ isActive }) =>
            `flex flex-col items-center justify-center space-y-1 min-w-[60px] group ${isActive ? 'is-active' : ''}`}
    >
        <div className="relative">
            {icon}
            {badge > 0 && <Badge badge={badge} className="absolute -top-2 -right-6 w-5 h-5 px-0"/>}
        </div>
        <span className="text-xs font-inter font-semibold text-white group-[.is-active]:text-primary-300">{title}</span>
    </NavLink>

const NavBar = () => {
    const { planned } = useTrips()
    const {
        selectedAccount
    } = useAccount()

    const items = [
        {
            title: 'Rit boeken',
            icon: <CarIcon className="fill-white w-8 h-8 group-[.is-active]:fill-primary-300"/>,
            path: '/boeken',
        },
        {
            title: 'Mijn ritten',
            icon: <TripIcon className="fill-white w-8 h-8 group-[.is-active]:fill-primary-300"/>,
            path: '/mijn-ritten',
        },
        {
            title: selectedAccount?.firstName || selectedAccount?.fullName || selectedAccount?.lastName || 'Account',
            icon: <div className="flex flex-row items-center relative">
                    <ProfileIcon className="fill-white w-8 h-8 group-[.is-active]:fill-primary-300 shrink-0"/>
                    {/*{accounts?.length > 1 && <Chevron className="fill-white group-[.is-active]:fill-primary-300  w-6 h-6 absolute -right-6"/>}*/}
                </div>,
            path: '/account',
        }]

    return (
        <div className="h-[90px] justify-center flex space-x-6 md:space-x-8 py-1 md:py-5">
            { items.map((item, index) =>
                <NavItem key={index} {...item} badge={index === 1 ? planned?.total : null }/>)}
        </div>
    )
}

export default NavBar
