import { Dialog } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'
import { AnimatePresence, motion } from 'framer-motion'

const Modal = ({ isOpen, children, className, hideCloseButton = false}) => {
    return (
        <AnimatePresence>
            {isOpen && (
                <Dialog
                    as="div"
                    className="fixed inset-0 z-[100]"
                    onClose={()=>{}}
                    open={isOpen}
                >
                    <div className="fixed inset-0">
                        <div className="flex items-center justify-center">
                            <Dialog.Panel
                                className={twMerge("relative", className)}
                                as={motion.div}
                                key="modal"
                                initial={{ y: "100vh", scale: 1 }}
                                animate={{ y: 0, scale:1  }}
                                exit={{
                                    y: "100vh", scale: 1,
                                    transition: {
                                        duration: 0.5,
                                        ease: "easeIn"
                                    },
                                }}
                                transition={{
                                    duration: 0.5,
                                    ease: "easeOut",
                                }}
                            >
                                {children}
                            </Dialog.Panel>
                        </div>
                    </div>
                </Dialog>
            )}
        </AnimatePresence>
    )
}

export default Modal
