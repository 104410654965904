import { Switch } from '@headlessui/react'
import { useEffect, useState } from 'react'
import Indications from '../atoms/Indications'
import {twMerge} from 'tailwind-merge';

const OptionCell = ({ icon, label, defaultValue, type, onChange, placeholder, className, readOnly = false }) => {
    const [enabled, setEnabled] = useState(defaultValue)

    useEffect(() => {
        onChange && onChange(enabled)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enabled])

    return (
        <div className={twMerge(
            "flex flex-row items-center justify-between w-full px-3 py-5 border-b border-b-grey-400 last:border-none",
            className
            )}>
            <div className="flex flex-row items-center space-x-3">
                <Indications acronym={icon} />
                {(label?.includes('(gratis)')) ? (
                    <>
                        <span className="text-primary-500 font-inter font-semibold text-base">{label?.replace('(gratis)', '')}</span>
                        <div className="flex flex-row items-center justify-center px-2 py-1 rounded-lg bg-primary-100">
                            <span className="text-primary-500 font-inter font-semibold text-xs">Gratis</span>
                        </div>
                    </>
                ) : <span className="text-primary-500 font-inter font-semibold text-base">{label}</span>}
            </div>
            {(
                type.toLowerCase() === 'boolean' ? (
                    <Switch
                        disabled={readOnly}
                        checked={enabled}
                        onChange={setEnabled}
                        className={`${enabled ? 'bg-primary-600' : 'bg-white'}
          relative inline-flex h-[22px] items-center w-[40px] shrink-0 cursor-pointer rounded-full border-2 border-primary-600 transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                    >
                        <span className="sr-only">Reisopties</span>
                        <span
                            aria-hidden="true"
                            className={`${enabled ? 'translate-x-5 bg-white' : 'translate-x-0.5 bg-primary-600'}
            pointer-events-none inline-block h-[16px] w-[16px] transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`}
                        />
                    </Switch>
                ) : (
                    <input
                        type={readOnly ? 'text' : 'number'}
                        disabled={readOnly}
                        min={0}
                        className="w-[70px] text-center border border-grey-400 rounded-lg px-3 py-2 text-base font-inter font-semibold text-primary-500"
                        defaultValue={defaultValue || null}
                        placeholder={placeholder}
                        onChange={(e) => setEnabled(e.target.value)}
                    />
                ))}
        </div>
    )
}

export default OptionCell
