import { EmptyMessage } from '../atoms'
import {Trans, useTranslation} from 'react-i18next'
import { ReactComponent as Phone } from '../../assets/icons/phone.svg'
import { ReactComponent as Mail } from '../../assets/icons/mail.svg'
import {useAccount} from '../../context/AccountContext';

const NoAccountMessage = () => {
    const { t } = useTranslation()
    const { selectedAccount } = useAccount()

    return (
        <div className="flex flex-col space-y-5 py-20 items-center h-full">
            <EmptyMessage
                className="pb-6"
                title={t('account.empty.title')}
                message={
                    <Trans i18nKey="account.empty.message" values={{email: selectedAccount?.email}}/>
                }
            />
            <a
                href="tel:+31887587654"
                className="min-w-[300px] p-5 bg-white border border-[#E0E0E0] rounded-[20px] flex
                                hover:border-primary-500 flex-row items-center justify-start">
                <div
                    className="w-12 h-12 rounded-2xl flex items-center justify-center shrink-0 bg-primary-200 mr-4 text-black font-inter">
                    <Phone className="w-7 h-7 fill-primary-500"/>
                </div>
                Telefoon: <span className="text-primary-500 font-bold ml-1">088 758 7654</span>
            </a>
            <a
                href="mailto:info@plusov.nl"
                className="min-w-[300px] p-5 bg-white border border-[#E0E0E0] rounded-[20px] flex
                                hover:border-primary-500 flex-row items-center justify-start">
                <div
                    className="w-12 h-12 rounded-2xl flex items-center justify-center shrink-0 bg-primary-200 mr-4 text-black font-inter">
                    <Mail className="w-7 h-7 fill-primary-500"/>
                </div>
                E-mail: <span className="text-primary-500 font-bold ml-1">info@plusov.nl</span>
            </a>
        </div>
    )
}

export default NoAccountMessage
