import { AddressButton, RadioInput } from '../molecules'
import { useTranslation } from 'react-i18next'
import { Button, States } from '../atoms/Button'
import { useEffect, useState } from 'react'
import { useTrips } from '../../context/TripContext'
import useModal from '../../utils/hooks/useModal'
import BookWizard from '../../screens/BookWizard'
import LocationSearch from '../../screens/LocationSearch'
import { LocationType, TripType } from '../../config/constants'
import { useAccount } from '../../context/AccountContext'
import { locationModel } from '../../utils/helper'

const BookForm = () => {
    const { tripConfig, updateTripConfig } = useTrips()
    const [isSubmitting] = useState(false)
    const [isHome, setIsHome] = useState(false)
    const [tripType, setTripType] = useState(TripType.TWO_WAY)
    const { t } = useTranslation()
    const { isOpen, closeModal, openModal } = useModal()
    const { isOpen: isOpenSearch, closeModal: closeSearch, openModal: openSearch } = useModal()
    const { isOpen: isOpenSearc, closeModal: closeSearc, openModal: openSearc } = useModal()
    const { selectedAccount, getLocationOfType } = useAccount()

    const tripOptions = [
        {
            id: 1,
            label: t('booking.step.two_way.label'),
            value: TripType.TWO_WAY,
        },
        {
            id: 2,
            label: t('booking.step.one_way.label'),
            value: TripType.ONE_WAY,
        }
    ]

    useEffect(() => {
        updateTripConfig('locationTo', null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        updateTripConfig('isReturn', tripType === TripType.TWO_WAY)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tripType])

    useEffect(() => {
        if (selectedAccount) {
            const home = getLocationOfType(LocationType.HOME, selectedAccount)?.address
            setIsHome(home === tripConfig?.locationFrom?.address)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount])

    const buttonState = () => {
        if (isSubmitting) return States.LOADING
        if (!tripConfig?.locationTo || !tripConfig?.locationFrom) return States.DISABLED
        return States.ACTIVE
    }

    const handleSwap = () => {
        const temp = tripConfig.locationFrom
        updateTripConfig('locationFrom', tripConfig.locationTo)
        updateTripConfig('locationTo', temp)
    }

    return (
        <div className="flex flex-col space-y-[25px] lg:space-y-[30px]">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 md:gap-6 lg:gap-12">
                <AddressButton
                    location={tripConfig?.locationFrom}
                    onSwap={handleSwap}
                    tabIndex={0}
                    onClick={openSearch}
                    placeholder={t('booking.step.departure.placeholder')}
                    showSwitcher
                    isHome={isHome}
                />
                <AddressButton
                    location={tripConfig?.locationTo}
                    tabIndex={0}
                    onClick={openSearc}
                    placeholder={t('booking.step.arrival.placeholder')}
                />
            </div>

            <div className="flex flex-col md:flex-row md:justify-between md:items-center space-y-[25px] md:space-y-0 lg:space-x-10">
                <div className="flex flex-row items-center space-x-5 md:space-x-10 ml-4 w-full">
                    {tripOptions.map((option, index) => (
                        <RadioInput
                            id={option.value}
                            name="trip_type"
                            label={option.label}
                            value={option.value}
                            key={index}
                            defaultChecked={index === 0}
                            onChange={(e) => setTripType(e.target.value)}
                        />
                    ))}
                </div>

                <Button tabIndex={0} className="w-full" onClick={() => {
                    updateTripConfig('isReturn', tripType === TripType.TWO_WAY)
                    openModal()
                }} state={buttonState()}>
                    {tripType === TripType.ONE_WAY
                        ? t('booking.button.book_single')
                        : t('booking.button.book_return')}
                </Button>
            </div>
            <BookWizard isOpen={isOpen} onClose={closeModal}/>
            <LocationSearch
                onClick={item => {
                    const loc = { ...locationModel(), ...item }
                    updateTripConfig('locationFrom', { ...tripConfig?.locationFrom, ...loc })
                }}
                isOpen={isOpenSearch}
                onClose={closeSearch}
                title={t('booking.step.departure.label')}
                defaultValue={tripConfig?.locationFrom}
            />
            <LocationSearch
                onClick={item => {
                    const loc = { ...locationModel(), ...item }
                    updateTripConfig('locationTo', { ...tripConfig?.locationTo, ...loc })
                }}
                isOpen={isOpenSearc}
                onClose={closeSearc}
                title={t('booking.step.arrival.label')}
                defaultValue={tripConfig?.locationTo}
            />
        </div>
    )
}

export default BookForm
