import { useField } from 'formik'
import ErrorMessage from '../atoms/ErrorMessage'
import { motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import { ReactComponent as Error } from '../../assets/icons/error.svg';

const States = {
    EMPTY: 'active',
    DISABLED: 'disabled',
    ERROR: 'error',
    DEFAULT: 'default',
}

const InputField = ({ label, showPasswordToggle = false, ...props }) => {
    const { t } = useTranslation()
    const { mstyle, mclass, type, className } = props
    const [field, meta] = useField(props)
    const [inputType, setInputType] = useState(type)
    const [s, setS] = useState(States.EMPTY)

    const style = {
        [States.EMPTY]: twMerge(
            "input-item",
            'bg-grey-200 border-grey-300 border',
            className
        ),
        [States.DISABLED]: twMerge(
            "input-item",
            'bg-grey text-grey-500',
            className
        ),
        [States.ERROR]: twMerge(
            "input-item",
            'bg-grey-300 border-secondary-300',
            className
        ),
        [States.DEFAULT]: twMerge(
            "input-item",
            className
        ),
    }

    useEffect(() => {
        if (meta.touched && meta.error) {
            setS(States.ERROR)
        } else if (props.disabled) {
            setS(States.DISABLED)
        } else if (field.value === "") {
            setS(States.EMPTY)
        } else {
            setS(States.DEFAULT)
        }
    }, [meta.touched, meta.error, props.disabled, field.value])

    return (
        <motion.div className={twMerge("relative", mclass)} style={mstyle}>
            <>
                {!!label && <label htmlFor={field.name} className="input-label select-none">{label}</label>}
                <div className="relative">
                    <input {...field} {...props} type={inputType} id={field.name} className={style[s]} autoCorrect="off" autoCapitalize="none"/>
                    {showPasswordToggle &&
                        <div
                            className="absolute cursor-pointer right-4 top-[22px] text-xs"
                            onClick={() => setInputType(inputType === "password" ? "text" : "password")}
                        >
                            <button type="button" className="text-xs text-primary-600 font-bold rounded-[6px] bg-grey-400 px-3 h-6">
                                {t(inputType === "password" ? "sign_in.form.password.show" : "sign_in.form.password.hide")}
                            </button>
                        </div>
                    }
                    {meta.touched && meta.error && <Error className="absolute cursor-pointer right-4 top-[18px] fill-secondary-600"/>}
                </div>
                {meta.touched && meta.error ? <ErrorMessage>{meta.error}</ErrorMessage> : null}
            </>

        </motion.div>
    )
}

export default InputField
