import React, {useMemo} from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import {createBrowserRouter, Navigate, RouterProvider} from 'react-router-dom'
import { Auth, Book, Layout, SignIn, SignUp, Trips} from './screens'
import Account from './screens/Account'
import './utils/i18n'
import { ForgotPasswordForm, SetPasswordForm, ConfirmForm } from './components/organisms'
import {TripProvider} from './context/TripContext'
import {MsalProvider} from "@azure/msal-react"
import { msalConfig } from './config/authConfig'
import {
    InteractionStatus,
    PublicClientApplication
} from '@azure/msal-browser'
import {
    useMsal,
    useIsAuthenticated,
} from '@azure/msal-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Loader from './components/atoms/Loader'
import {Toasty} from './components/molecules'
import {AccountProvider} from './context/AccountContext'
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://f4e21030581254d40d2b82294ad0d5df@o4507526657343488.ingest.de.sentry.io/4507526660620368",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            //staleTime: 1000 * 60 * 2,
            refetchOnWindowFocus: false,
            retry: false,
        }
    }
})

const Router = () => {
    const isAuthenticated = useIsAuthenticated()
    const { inProgress} = useMsal()

    const authRouter = useMemo(() => createBrowserRouter([
        {
            path: '/',
            element: <Layout />,
            children: [
                { path: '/', element: <Navigate to="/boeken" replace /> },
                { path: 'boeken', element: <Book /> },
                { path: 'mijn-ritten', element: <Navigate to="/mijn-ritten/gepland" replace /> },
                { path: 'mijn-ritten/:tripType?', element: <Trips /> },
                { path: 'mijn-ritten/gepland/:tripId', element: <Trips /> },
                { path: 'mijn-ritten/rithistorie/:tripId', element: <Trips /> },
                { path: 'account', element: <Account /> },
            ]
        },
        {
            path: '*', element: <Navigate to="/" replace />
        }
    ]), [])

    const unAuthRouter = createBrowserRouter([
        {
            path: '/',
            element: <Auth />,
            children: [
                { path: '/', element: <Navigate to="/inloggen" replace /> },
                { path: 'inloggen', element: <SignIn /> },
                { path: 'wachtwoord-vergeten', element: <ForgotPasswordForm /> },
                { path: 'registreren', element: <SignUp /> },
                { path: 'activatiecode', element: <ConfirmForm /> },
                { path: 'wachtwoord-instellen', element: <SetPasswordForm /> },
            ]
        },
        {
            path: '*', element: <Navigate to="/" replace />
        }
    ])

    if (inProgress !== InteractionStatus.None) {
        return <Loader/>
    }

    // Conditional rendering based on authentication state
    const router = isAuthenticated ? authRouter : unAuthRouter;

    return <RouterProvider router={router} />
}

const App = () => {
    const msalInstance = new PublicClientApplication(msalConfig)

    return (
        <MsalProvider instance={msalInstance}>
            <QueryClientProvider client={queryClient}>
                <AccountProvider>
                    <TripProvider>
                        <Router/>
                    </TripProvider>
                </AccountProvider>
            </QueryClientProvider>
        </MsalProvider>
    )
}

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <App/>
        <Toasty />
    </React.StrictMode>
)

reportWebVitals()
