import { Button, States } from '../components/atoms/Button'
import { useTranslation } from 'react-i18next'
import ValueLabel from '../components/atoms/ValueLabel'
import { twMerge } from 'tailwind-merge'
import { DialogConfirm } from '../components/molecules'
import useModal from '../utils/hooks/useModal'
import { ReactComponent as Lock } from '../assets/icons/lock.svg'
import { useMsal } from "@azure/msal-react"
import Loader from '../components/atoms/Loader'
import { useAccount } from '../context/AccountContext'
import { LocationType } from '../config/constants'
import Indications from '../components/atoms/Indications'
import ListBox from '../components/molecules/ListBox'
import packageJson from '../../package.json'
import TripExportForm from './TripExportForm'
import NoAccountMessage from '../components/organisms/NoAccountMessage';

const Account = () => {
    const {t} = useTranslation()
    const {isOpen, closeModal, openModal} = useModal()
    const {instance} = useMsal()
    const {
        accounts,
        getLocationOfType,
        selectedAccount,
        isLoading
    } = useAccount()
    const { isOpen: isOpenExport, closeModal: closeExport, openModal: openExport } = useModal()

    const handleLogout = () => instance.logout()

    const SectionTitle = ({ title }) =>
        <h2 className="font-inter text-xl mb-3 text-grey-600 font-semibold px-4">{title}</h2>

    const Item = ({ title, icon, className }) =>
        <div className={twMerge("flex flex-row items-center space-x-3 w-full", className)}>
            <Indications acronym={icon}/>
            {
                (title.includes('(gratis)')) ? (
                    <>
                        <span className="text-primary-500 font-inter font-semibold text-base">{title.replace('(gratis)', '')}</span>
                        <div className="flex flex-row items-center justify-center px-2 py-1 rounded-lg bg-primary-100">
                            <span className="text-primary-500 font-inter font-semibold text-xs">Gratis</span>
                        </div>
                    </>
                ) : <span className="text-primary-500 font-inter font-semibold text-base">{title}</span>
            }
        </div>

    if (isLoading) {
        return <Loader/>
    }

    if (accounts?.length === 0) {
        return (
            <NoAccountMessage/>
        )
    }

    return (
        <div className="relative w-full md:w-full md:min-w-[620px] md:px-[60px] lg:px-0 lg:w-full lg:max-w-[933px] m-auto px-2.5 md:px-0 pt-8 space-y-5">
            {accounts?.length > 1 && <ListBox/>}
            <h1 className="hidden lg:flex font-inter font-semibold text-primary-500 text-[28px] px-4 py-4">
                Account
            </h1>
            <div className="flex flex-col lg:flex-row space-y-8 lg:space-y-0 lg:space-x-8 relative">
                <div className="w-full">
                    <SectionTitle title={t('account.title')}/>
                    <div className="flex flex-col w-full bg-white rounded-[20px] border border-grey-400">
                        <ValueLabel
                            title={selectedAccount?.fullName}
                            subtitle={selectedAccount?.email}
                            className="p-5 border-b border-b-grey-400 "
                        />
                        <ValueLabel
                            title={t('account.form.phone')}
                            subtitle={!!selectedAccount?.mobileNumber ? selectedAccount?.mobileNumber : "-"}
                            className="p-5 border-b border-b-grey-400 "
                        />
                        <ValueLabel
                            title={t('account.form.home_address')}
                            subtitle={getLocationOfType(LocationType.HOME, selectedAccount)?.address}
                            className="p-5"
                        />
                        <div className="hidden lg:flex border-t border-t-grey-400">
                            <Button
                                className="m-5 hidden lg:flex w-full"
                                state={States.OUTLINED}
                                onClick={openModal}
                            >
                                {t('account.button.logout')}
                            </Button>
                        </div>

                    </div>
                    <Button
                        className="px-3 hidden lg:block bg-white border border-grey-400 !text-primary-300 w-full mt-4"
                        state={States.OUTLINED}
                        onClick={openExport}
                    >
                        {t('account.button.download')}
                    </Button>
                </div>
                <Button
                    className="px-3 lg:hidden bg-white border border-grey-400 !text-primary-300"
                    state={States.OUTLINED}
                    onClick={openExport}
                >
                    {t('account.button.download')}
                </Button>
                <div className="w-full h-auto self-stretch bottom-0">
                    <SectionTitle title={t('account.options')}/>
                    <div className="flex flex-col w-full bg-white rounded-[20px] border border-grey-400">
                        {selectedAccount?.customerSubcontracts[0]?.attributes?.map((attribute, index) =>
                            <Item
                                key={`${index}-${attribute?.attributeType?.name}`}
                                title={attribute?.attributeType?.name}
                                icon={attribute?.attributeType?.key}
                                className="p-5 border-b border-b-grey-400 "
                            />
                        )}
                    </div>
                </div>
                <Button
                    className="px-3 lg:hidden"
                    state={States.OUTLINED}
                    onClick={openModal}
                >
                    {t('account.button.logout')}
                </Button>
            </div>
            <span className="font-inter text-center pt-8 text-xs text-primary-500/50 w-full block">
                    {t('version.title')} {packageJson.version}
                </span>
            <DialogConfirm
                icon={<div className="w-12 h-12 bg-primary-200 flex items-center justify-center rounded-full">
                    <Lock className=""/>
                </div>}
                isOpen={isOpen}
                onClose={closeModal}
                onConfirm={handleLogout}
                title={t('dialog.logout.title')}
                body={t('dialog.logout.body')}
                confirm={t('dialog.logout.button.confirm')}
                cancel={t('dialog.logout.button.cancel')}
            />

            <TripExportForm isOpen={isOpenExport} onClose={closeExport}/>
        </div>
    )
}

export default Account
