import { twMerge } from 'tailwind-merge'
import { ReactComponent as Trip } from '../../assets/icons/error.svg'

const EmptyMessage = ({ title, message, className, children }) => (
    <div className={twMerge("flex flex-1 w-full h-full items-center justify-center", className)}>
        <div className="text-white w-1/2 max-w-[300px] h-full items-center flex flex-col justify-center">
            <div className="w-12 h-12 rounded-2xl flex items-center justify-center shrink-0 bg-grey-500/30 -mt-10 mb-2.5">
                <Trip className="w-7 h-7 fill-primary-500"/>
            </div>
            <h1 className="font-inter text-lg font-bold text-primary-500 text-center mt-1">{title}</h1>
            <span className="whitespace-pre-wrap font-inter font-normal text-grey-600 text-sm text-center mt-1">
                {message}
            </span>
            {children}
        </div>
    </div>
)

export default EmptyMessage
