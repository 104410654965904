const resources = {
    nl: {
        translation: {
            "navigation": {
                "home": "Home",
                "back": "Terug",
            },
            "sign_in": {
                "title": "Inloggen",
                "body": "Log in met je opt1route account om toegang te krijgen tot het boekingssysteem.",
                "form": {
                    "email": {
                        "label": "E-mailadres",
                        "placeholder": "Je e-mailadres"
                    },
                    "password": {
                        "label": "Wachtwoord",
                        "placeholder": "Wachtwoord",
                        "show": "Toon",
                        "hide": "Verberg"
                    }
                },
                "submit": "Inloggen",
                "forgot_password": "Wachtwoord vergeten?",
                "already_account": "Nog geen account?",
                "link_create_account": "Account aanmaken",
                "button": {
                    "sign_in": "Inloggen",
                    "sign_up": "Account aanmaken",
                    "send": "Versturen"
                }
            },
            "forgot_password": {
                "title": "Wachtwoord vergeten",
                "body": "Vul je e-mailadres in om een link te ontvangen om je wachtwoord te resetten.",
                "title_success": "E-mail verstuurd",
                "body_success": "Er is een e-mail verstuurd naar <strong>{{email}}</strong> met een link om je wachtwoord te resetten.",
                "form": {
                    "email": {
                        "label": "E-mailadres",
                        "placeholder": "Je e-mailadres"
                    }
                },
                "button": {
                    "send": "Versturen"
                },
            },
            "sign_up": {
                "title": "Account aanmaken",
                "body": "Maak een opt1route account aan om toegang te krijgen tot het boekingssysteem.",
                "form": {
                    "email": {
                        "label": "E-mailadres",
                        "placeholder": "Je e-mailadres"
                    },
                    "password": {
                        "label": "Wachtwoord",
                        "placeholder": "Wachtwoord",
                        "show": "Toon",
                        "hide": "Verberg"
                    },
                },
                "already_account": "Al een account?",
                "link_sign_in": "Inloggen",
            },
            "confirm": {
                "title": "Activatiecode",
                "body": "Er is een code verstuurd naar je e-mail voer de code in om door te gaan.",
                "form": {
                    "code": {
                        "label": "Activatiecode",
                        "placeholder": "Je activatiecode"
                    }
                },
                "button": {
                    "send": "Bevestigen"
                }
            },
            "set_password": {
                "title": "Account geactiveerd!",
                "body": "Kies nu een <strong>eigen wachtwoord</strong> om mee in te loggen",
                "form": {
                    "password": {
                        "label": "Wachtwoord",
                        "placeholder": "Je wachtwoord",
                    },
                    "password_confirmation": {
                        "label": "Wachtwoord bevestigen",
                        "placeholder": "Bevestig je wachtwoord",
                    }
                },
                "button": {
                    "send": "Wachtwoord instellen"
                }
            },
            "error": {
                "username": {
                    "required": "E-mailadres is verplicht",
                },
                "password": {
                    "required": "Wachtwoord is verplicht",
                },
                "required": "Dit veld is verplicht",
                "email": {
                    "required": "E-mailadres is verplicht",
                    "invalid": "E-mailadres is ongeldig",
                }
            },
            "booking": {
                "title": 'Rit boeken',
                "title_user": "Rit boeken voor {{name}}",
                "title_departure": "Vertrek",
                "title_arrival": "Aankomst",
                "step": {
                    "address": {
                        "label": "Adres",
                        "placeholder": "Zoek een adres, station of locatie",
                    },
                    "arrival": {
                        "label": "Aankomst",
                        "placeholder": "Waar wil je naar toe?",
                    },
                    "departure": {
                        "label": "Vertrek",
                        "placeholder": "Waar wil je vertrekken?",
                    },
                    "phone": {
                        "title": "Wat is je telefoonnummer",
                        "info": "Ontvang enkele minuten voordat de taxi bij je voorrijdt een telefoontje, zodat je weet dat de chauffeur eraan komt.",
                        "form": {
                            "label": "Telefoonnummer",
                            "placeholder": "Wat is je telefoonnummer",
                        }
                    },
                    "date": {
                        "title": "Wanneer wil je vertrekken?",
                        "info": "",
                        "form": {
                            "label": "Datum",
                            "placeholder": "Wanneer wil je vertrekken?",
                        }
                    },
                    "time": {
                        "title": "Hoe laat wil je vertrekken?",
                        "info": "Je wordt opgehaald in een tijdvenster van ongeveer 15 minuten.",
                        "form": {
                            "label": "Ophaaltijd",
                            "placeholder": "Hoe laat wil je vertrekken?",
                        }
                    },
                    "time_return": {
                        "title": "Plan direct de terugreis",
                        "info": "Hoe laat halen we je weer op?",
                        "form": {
                            "label": "Ophaaltijd",
                            "placeholder": "Hoe laat wil je terug?",
                        }
                    },
                    "indications": {
                        "title": "Hoe reis je?",
                        "info": "Zijn er medereizigers of hulpmiddelen?",
                    },
                    "notes": {
                        "title": "Opmerkingen",
                        "info": "Zijn er dingen waar de chauffeur op moet letten bij het ophalen?",
                        "placeholder": "Heb je nog opmerkingen?",
                    },
                    "summary": {
                        "title": "Overzicht",
                        "info": "Controleer de gegevens van je reis op <strong>{{date}}</strong>.",
                    },
                    "price": {
                        "label": "Totale prijs",
                        "suffix_single": "voor {{count}} persoon",
                        "suffix_multiple": "voor {{count}} personen",
                    },
                    "one_way": {
                        "label": "Enkele reis",
                        "value": "one_way",
                    },
                    "two_way": {
                        "label": "Retour",
                        "value": "two_way",
                    },
                },
                "button": {
                    "book_return": "Boek heen- en terugreis",
                    "book_single": "Boek enkele reis",
                    "next": "Volgende stap",
                    "confirm": "Nu boeken"
                }
            },
            "trip": {
                "title": "Rit informatie",
                "callcenter": {
                    "title": "Telefonisch geboekt",
                    "body": "Je kunt deze rit wijzigen door te bellen met het callcenter",
                }
            },
            "update": {
                "title": "Rit wijzigen",
                "button": {
                    "date": "Datum wijzigen",
                    "time": "Tijd wijzigen",
                    "attributes": "Wijzigen",
                }
            },
            "processing": {
                "plan": {
                    "title": "Rit plannen",
                    "body": "We zijn je rit aan het plannen",
                },
                "confirm": {
                    "title": "Boeking geaccepteerd",
                    "body": "Je ritten zijn succesvol ingepland en toegevoegd aan <strong>Mijn ritten</strong>",
                },
                "button": {
                    "continue": "Doorgaan",
                }
            },
            "trips": {
                "planned": {
                    "title": "Geplande ritten",
                    "empty": "Je hebt nog <strong>geen</strong> ritten gepland",
                    "filled": "Je hebt op dit moment <strong>{{count}}</strong> rit gepland",
                    "filled_plural": "Je hebt op dit moment  <strong>{{count}}</strong> ritten gepland",
                },
                "history": {
                    "title": "Historie",
                    "empty": "Je hebt nog <strong>geen</strong> ritten gemaakt",
                    "archived": "Een overzicht van je voltooide ritten",
                },
                "button": {
                    "load_more": "Laad meer ritten",
                }
            },
            "account": {
                "title": "Mijn gegevens",
                "form": {
                    "phone": "Telefoonnummer",
                    "email": "E-mailadres",
                    "home_address": "Thuisadres",
                },
                "options": "Indicaties",
                "button": {
                    "logout": "Uitloggen",
                    "download": "Ritoverzicht downloaden",
                    "pdf": "PDF downloaden",
                    "retrieve": "Ritten ophalen",
                },
                "empty": {
                    "title": "Geen klantgegevens bekend",
                    "message": "Het e-mailadres: <strong>{{email}}</strong> is niet bekend bij PlusOV. \n\n" +
                        "Om te kunnen boeken en uw ritgegevens in te kunnen zien kunt u contact " +
                        "opnemen met PlusOV om dit e-mailadres aan uw klantgegevens te laten koppelen.",
                }
            },
            "dialog": {
                "logout": {
                    "title": "Uitloggen",
                    "body": "Weet je zeker dat je wilt uitloggen?",
                    "button": {
                        "cancel": "Nee, toch niet",
                        "confirm": "Ik wil uitloggen",
                    }

                },
                "cancel": {
                    "title": "Rit annuleren",
                    "body": "Weet je zeker dat je deze rit wilt annuleren?",
                    "button": {
                        "cancel": "Nee, toch niet",
                        "confirm": "Ik wil annuleren",
                    }
                },
                "abort": {
                    "title": "Stoppen met boeken",
                    "body": "Weet je zeker dat je wilt stoppen met boeken?",
                    "button": {
                        "cancel": "Nee, toch niet",
                        "confirm": "Ik wil stoppen",
                    }
                }
            },
            "empty_message": {
                "planned": {
                    "title": "Geen geplande ritten",
                    "body": "Je hebt nog geen ritten gepland",
                },
                "history": {
                    "title": "Geen historie",
                    "body": "Je hebt nog geen ritten gemaakt",
                }
            },
            "aria": {
                "button": {
                    "trip": {
                        "delete": "Rit annuleren",
                        "edit": "Rit wijzigen",
                    },
                },
            },
            "version": {
                "title": "versie",
            }
        }
    }
};

export {
    resources
}


