// const ENDPOINT_PROD = '/api'
// const ENDPOINT_DEV = '/api'
const ENDPOINT_PROD = '/api'
const ENDPOINT_DEV = 'https://k8s.opt1dev.nl/api'

const BASE_URL = process.env.REACT_APP_HOST_ENV === 'prod' ? ENDPOINT_PROD : ENDPOINT_DEV

const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

const ListTripAction = {
    ALL: 'all',
    PLANNED: 'planned',
    ARCHIVED: 'archived',
    ALL_REFRESH: 'allRefresh',
    PLANNED_REFRESH: 'plannedRefresh',
    ARCHIVED_REFRESH: 'archivedRefresh',
}

const TripStatus = {
    ACCEPT: 'ACCEPT',
    ARCHIVED: 'ARCHIVED',
    ASSIGN: 'ASSIGN',
    AUTOPLAN: 'AUTOPLAN',
    CANCEL: 'CANCEL',
    CKI: 'CKI',
    CKO: 'CKO',
    CONFIRM: 'CONFIRM',
    PLAN: 'PLAN',
    REJECT: 'REJECT',
    WITHDRAW: 'WITHDRAW',
    TOBEOUTSOURCED: 'TOBEOUTSOURCED',
    OUTSOURCED: 'OUTSOURCED',
    BLUEPRINT: 'BLUEPRINT',
    NOSHOW: 'NOSHOW',
}

const AccessType = {
    READONLY: 'ReadOnly',
    HIDDEN: 'Hidden',
    WRITABLE: 'Writable',
    CUSTOMERRESTRICTED: 'CustomerRestricted',
    PASSENGERRESTRICTED: 'PassengerRestricted',
    SYSTEM: 'System',
    USERRESTRICTED: 'UserRestricted',
}

const AttributeType = {
    PRIORITY: 'PRIORITY',
    FORCE_DIRECT_BOOKING: 'FORCEDIRECT',
}

const TripType = {
    ONE_WAY: 'single',
    TWO_WAY: 'return',
}

const LocationType = {
    AIRPORT: 'airport',
    HOME: 'Home',
}

const UpdateWizardMode = {
    DATE: "date",
    TIME: "time",
    ATTRIBUTES: "attributes",
    COMMENT: "comment"
}

export {
    EMAIL_REGEX,
    BASE_URL,
    ListTripAction,
    TripType,
    LocationType,
    TripStatus,
    AccessType,
    AttributeType,
    UpdateWizardMode
}
