const steps = [
    {
        title: 'booking.step.date.title',
        body: 'booking.step.date.info',
        label: 'booking.step.date.form.label',
        placeholder: 'booking.step.date.form.placeholder',
        showPrice: false,
    },
    {
        title: 'booking.step.time.title',
        body: 'booking.step.time.info',
        label: 'booking.step.time.form.label',
        placeholder: 'booking.step.time.form.placeholder',
        showPrice: false,
    },
    {
        title: 'booking.step.time_return.title',
        body: 'booking.step.time_return.info',
        label: 'booking.step.time_return.form.label',
        placeholder: 'booking.step.time_return.form.placeholder',
        showPrice: false,
    },
    {
        title: 'booking.step.phone.title',
        body: 'booking.step.phone.info',
        label: 'booking.step.phone.form.label',
        placeholder: 'booking.step.phone.form.placeholder',
        showPrice: false,
    },
    {
        title: 'booking.step.indications.title',
        body: 'booking.step.indications.info',
        showPrice: true,
    },
    {
        title: 'booking.step.notes.title',
        body: 'booking.step.notes.info',
        showPrice: true,
    },
    {
        title: 'booking.step.summary.title',
        body: 'booking.step.summary.info',
        showPrice: true,
    },
]

export {
    steps
}
