import useApi, {HTTPMethod} from './useApi'
import { useCallback, useEffect, useState } from 'react'

import {generateGUID} from '../helper'

const useLocationSearch = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState(null)

    const [request, setRequest] = useState("")
    const [timer, setTimer] = useState(null)

    const {callApi} = useApi()

    //const debouncedCallback = useDebouncedCallback(async () => {

    const fetchData = useCallback(async () => {
        setIsLoading(true)
        setError(null)

        try {
            //const d = resultsB
            const data =  await callApi(
                `InputParser?zoek=${encodeURI(request)}&skip=0&take=100`,
                null, HTTPMethod.GET,
                false,
                '/opt1locateapi',
                {
                    'Content-Type': 'application/json',
                    'Opt1apikey': 'b4684fed-8ff2-4ba9-8645-e481aa492077',
                }
            )

            const response = parseResponse(data)
            setData(response)
        } catch (err) {
            setError(err)
        } finally {
            setIsLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [request]) // 300ms delay, adjust as needed

    useEffect(() => {
        setData(null)
    },[])

    useEffect(() => {
        if (timer) clearTimeout(timer);

        setTimer(setTimeout(() => {
            if (!!request && request.value !== "") {
                fetchData()
            }
        }, 300)) // 300ms delay, adjust as needed

        return () => {
            if (timer) clearTimeout(timer) // Clean up timer on unmount
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [request, fetchData])

    const setSearchRequest = (newRequest) => {
        setRequest(newRequest)
    }

    const formattedTitle = (loc) => {
        if (loc.type === "huisnummerreeks") {
            return `${loc?.street} ${loc?.hsnrSeriesFirst} - ${loc?.hsnrSeriesLast}`
        }

        return loc?.poiName ?? `${loc?.street} ${loc?.houseNumber || ""}`
    }


    const formattedAddress = (loc) =>
        (loc?.poiName) ? `${loc?.street ?? ""} ${loc?.houseNumber + "," ?? ""} ${loc?.place ?? ""}` : loc?.place ?? ""

    const reset = () => {
        setRequest(null)
        setData(null)
    }

    const parseResponse = (geocoderResponse) => {
        const response = {
            id: request.id,
            invokerId: request.id,
            correlationId: request.correlationId,
            isSucceeded: true,
            records: [],
            totalRecordsCount: geocoderResponse.pageNavigate?.itemCount || 0,
        }

        if (geocoderResponse.resultItems?.length) {
            response.records = geocoderResponse.resultItems.map((resultItem) => {
                let provincie= {}
                let gemeente= {}
                let straat= {}
                let plaats= {}
                let postcode= {}
                let pc4= {}
                let hsnrReeks= {}
                let adres= {}
                let poiType= {}
                let poi= {}

                let type= resultItem.inputParserResultType

                let parsedItem = {}

                switch (resultItem.inputParserResultType) {
                    case 'plaats': {
                        plaats = geocoderResponse.plaatsen[resultItem.index]
                        gemeente = geocoderResponse.gemeentes[plaats.gemeenteIdx]
                        provincie = geocoderResponse.provincies[gemeente.provincieIdx]
                        break
                    }
                    case 'straat': {
                        straat = geocoderResponse.straten[resultItem.index]
                        plaats = geocoderResponse.plaatsen[straat.plaatsIdx]
                        gemeente = geocoderResponse.gemeentes[plaats.gemeenteIdx]
                        provincie = geocoderResponse.provincies[gemeente.provincieIdx]
                        break
                    }
                    case 'huisnummerreeks': {
                        hsnrReeks = geocoderResponse.huisnummerReeksen[resultItem.index]
                        straat = geocoderResponse.straten[hsnrReeks.straatIdx]
                        plaats = geocoderResponse.plaatsen[straat.plaatsIdx]
                        gemeente = geocoderResponse.gemeentes[plaats.gemeenteIdx]
                        provincie = geocoderResponse.provincies[gemeente.provincieIdx]
                        postcode = hsnrReeks.postcodeIdx > -1 ? geocoderResponse.postcodes[hsnrReeks.postcodeIdx] : null
                        pc4 = postcode ? geocoderResponse.pc4s[postcode.pc4Idx] : null
                        break
                    }

                    case 'adres': {
                        adres = geocoderResponse.adressen[resultItem.index]
                        hsnrReeks = geocoderResponse.huisnummerReeksen[adres.huisnummerReeksIdx]
                        straat = geocoderResponse.straten[hsnrReeks.straatIdx]
                        plaats = geocoderResponse.plaatsen[straat.plaatsIdx]
                        gemeente = geocoderResponse.gemeentes[plaats.gemeenteIdx]
                        provincie = geocoderResponse.provincies[gemeente.provincieIdx]
                        postcode = hsnrReeks.postcodeIdx > -1 ? geocoderResponse.postcodes[hsnrReeks.postcodeIdx] : null
                        pc4 = postcode ? geocoderResponse.pc4s[postcode.pc4Idx] : null
                        break
                    }
                    case 'poi': {
                        poi = geocoderResponse.pois[resultItem.index]
                        poiType = geocoderResponse.poiTypes[poi.poiTypeIdx]
                        adres = geocoderResponse.adressen[poi.adresIdx]
                        hsnrReeks = geocoderResponse.huisnummerReeksen[adres.huisnummerReeksIdx]
                        straat = geocoderResponse.straten[hsnrReeks.straatIdx]
                        plaats = geocoderResponse.plaatsen[straat.plaatsIdx]
                        gemeente = geocoderResponse.gemeentes[plaats.gemeenteIdx]
                        provincie = geocoderResponse.provincies[gemeente.provincieIdx]
                        postcode = hsnrReeks.postcodeIdx > -1 ? geocoderResponse.postcodes[hsnrReeks.postcodeIdx] : null
                        pc4 = postcode ? geocoderResponse.pc4s[postcode.pc4Idx] : null

                        break
                    }
                    default:
                        break
                }
                parsedItem = {
                    type: type,
                    countryCode: "NL",
                    poiName: poi?.naam,
                    poiDescription: poi?.detail,
                    poiTypeCode: poiType?.afkorting,
                    poiTypeName: poiType?.omschrijving,
                    poiTypeIcon: poiType?.iconify,
                    street: straat?.straatnaam,
                    houseNumber: adres?.huisnummer,
                    hsnrSeriesFirst: hsnrReeks?.first,
                    hsnrSeriesLast: hsnrReeks?.last,
                    place: plaats?.plaatsNaam,
                    municipalityId: gemeente?.gemeenteId,
                    municipalityName: gemeente?.gemeenteNaam,
                    provinceId: provincie?.provincieId,
                    provinceName: provincie?.provincieNaam,
                    zip: postcode && pc4 ? `${pc4.pc4}${postcode.letters}` : null,
                    latitude: adres?.latitude,
                    longitude: adres?.longitude,
                    additional: !poi ? adres?.gebruiksdoel : null,
                    id: generateGUID(),
                    version: generateGUID(),
                }
                return parsedItem
            })
        }

        return response
    }

    return { isLoading, error, data, request, setSearchRequest, formattedTitle, formattedAddress, reset }
}

export default useLocationSearch
