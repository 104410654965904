import {useTranslation} from 'react-i18next'
import {twMerge} from 'tailwind-merge'

const
    PriceCell = ({ price, className }) => {
    const { t } = useTranslation()

    return (
        <div className={
            twMerge(
                "flex flex-row items-center w-full pl-5 pr-2 py-2 space-x-3 bg-white rounded-[20px] border border-grey-400 justify-between",
                "rounded-none border-0 border-t px-5",
                className)}
        >
            <div>
                <span className="font-inter text-base font-semibold text-grey-600">
                    {t('booking.step.price.label')}
                </span>
                    <span className="font-inter text-sm font-normal text-grey-600 ml-1">
                    {t(price?.passengerCount === 1
                        ? 'booking.step.price.suffix_single'
                        : 'booking.step.price.suffix_multiple', {count: price?.passengerCount})}
                </span>
            </div>

            <span className="flex w-20 h-10 text-base font-inter font-bold items-center justify-center rounded-[12px] shrink-0 text-primary-500 bg-primary-100">
                {'€ ' + price?.total.toLocaleString('nl', {minimumFractionDigits: 2})}
            </span>
        </div>
    )
}

export default PriceCell
