import useApi, { HTTPMethod } from './hooks/useApi'
import {BASE_URL, ListTripAction} from '../config/constants'
import { useMutation } from '@tanstack/react-query'
import {useState, useCallback} from 'react'
import {useAccount } from '../context/AccountContext'

/**
 * Helper function to create mutations with useApi
 *
 * @param queryKey
 * @param endpoint
 * @param body
 * @returns {UseMutationResult<any|string|Response, unknown, void, unknown>}
 * @returns {UseMutationResult<any|string|Response, unknown, void, unknown>}
 */
export const useMutate = (queryKey, endpoint = {}) => {
    const { callApi } = useApi()

    return useMutation({
        queryKey: [queryKey],
        mutationFn: async (vars) =>
            await callApi(endpoint, vars, HTTPMethod.POST, true, BASE_URL),
        onSuccess: (data) => {
            return data
        }
    })
}

export const useCreateTrip = () =>
    useMutate(
        'useCreateTrip',
        'trip/CreateTripEditorModels')

export const useListTrips = (customerId) => {
    const { callApi } = useApi()
    const { selectedAccount } = useAccount()
    const [planned, setPlanned] = useState({ trips: [], total: 0 })
    const [archived, setArchived] = useState({ trips: [], total: 0 })

    const ARCHIVED_FILTERS = [
        "c09699ff-6e81-4d49-a3b6-4d53feb52c32",  // ARCHIVED
        "9a2c8582-1d62-4424-ac66-395b41b84971", // CKO
        "abf110fb-bd0e-4ab3-bbfb-bda60322fc6d", // CANCEL
        "8bf9281d-de38-4ff2-b6ed-23297f20f7ac" // NOSHOW
    ]

    const PLANNED_FILTERS = [
        "6da77ae8-92ad-4515-9cb4-d637a4e9a709", // ACCEPT
        "5278e7c4-a498-48ca-8639-52177d74100d", // AUTOPLAN
        "7d91d327-d483-478e-adde-0cee3a8b687b", // PLAN
        "a25403c6-8d4d-4344-9b1b-ce57106fa208", // ASSIGN
        "ebe2dae7-6f2f-4bf2-8220-12228aec8913", // OUTSOURCED
        "543be982-c8a2-4f50-9513-6c6eef9d8bac", // REJECT
        "0f217c1e-688d-4fca-af4c-ec5d250973a2", // TOBEOUTSOURCED
        "08d29ca3-b5d7-490c-9252-c4bca6d611a1", // WITHDRAW
        "b2aabdd5-bc63-4fc9-8a3d-5f3a725cf9c2", // CONFIRM
        "08565115-35f1-4efe-a355-ed04e999156a", // CKI
    ]

    const fetchTrips = useCallback((isArchived) => {
        const filters = isArchived ? ARCHIVED_FILTERS : PLANNED_FILTERS
        const setTripsState = isArchived ? setArchived : setPlanned

        return async () => {
            try {
                const response = await callApi(
                    "trip/loadtriptablemodels",
                    {
                        filter: {
                            customerIds: [selectedAccount?.id],
                            filter: "",
                            isDeleted: false,
                            loadTotalRecordsCount: true,
                            tripStatusIds: filters,
                        },
                        page: {
                            recordIndex: (isArchived ? archived : planned).trips.length,
                            recordCount: 10
                        },
                        sorts: [{ column: "pickupDateTime", direction: isArchived ? 1 : 0 }]
                    },
                    HTTPMethod.POST,
                    true, BASE_URL
                );
                handleSuccess(response, setTripsState)
            } catch (error) {
                console.error("Error loading trips:", error)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount, callApi, ARCHIVED_FILTERS, PLANNED_FILTERS])

    const sortableValue = (item) =>
        new Date(item.desiredTimeType.name === "PICKUP" ? item?.pickupDateTime : item?.deliveryDateTime)

    const handleSuccess = useCallback((data, setTripsState) => {
        if (data) {
            const sortedTrips = data.records
                .filter(trip => trip.customerSubcontract.customer.id === selectedAccount?.id)

            setTripsState(prevTrips => ({
                trips: [...prevTrips.trips, ...sortedTrips].sort((a, b) => sortableValue(a) + sortableValue(b)),
                total: data.totalRecordsCount
            }))
        }
    }, [selectedAccount?.id])

    const { isLoading: isLoadingArchived, mutate: listArchivedTrips } = useMutation(fetchTrips(true))
    const { isLoading: isLoadingPlanned, mutate: listPlannedTrips } = useMutation(fetchTrips(false))

    const listTrips = (action) => {
        switch (action) {
            case ListTripAction.PLANNED:
                listPlannedTrips()
                break
            case ListTripAction.ARCHIVED:
                listArchivedTrips()
                break
            case ListTripAction.ALL:
            default:
                listPlannedTrips()
                listArchivedTrips()
                break
        }
    }

    return {
        planned,
        archived,
        isLoading: isLoadingArchived || isLoadingPlanned,
        listTrips,
        clearPlannedTrips: () => setPlanned({ trips: [], total: 0 }),
        clearArchivedTrips: () => setArchived({ trips: [], total: 0 }),
    }
}

/**
 * ACCOUNT - Fetches the customer table model
 *
 * @returns {UseMutationResult<any|string|Response, unknown, void, unknown>}
 */
export const useCustomerSubContract = ()=>
    useMutate(
        'useCustomerSubContract',
        'customersubcontract/loadcustomersubcontracttablemodels')


/**
 * ACCOUNT - Fetches the customer editor model
 *
 * @returns {UseMutationResult<any|string|Response, unknown, void, unknown>}
 */
export const useCustomerSubContractEditor = ()=>
    useMutate(
        'useCustomerSubContractEditor',
        'customersubcontract/loadcustomersubcontracteditormodels')

/**
 * ACCOUNT - Fetches the customer table model
 *
 * @returns {UseMutationResult<any|string|Response, unknown, void, unknown>}
 */
export const useCustomerEditorModel = () =>
    useMutate(
        'useCustomerEditorModel',
        'customer/loadcustomereditormodels')

