import {Trans, useTranslation} from 'react-i18next'
import React, {useEffect, useMemo, useState} from 'react'
import { ReactComponent as Close } from '../assets/icons/clost.svg'
import { ReactComponent as Error } from '../assets/icons/error.svg'
import { ReactComponent as Arrow } from '../assets/icons/arrow-l.svg'
import { Button, States } from '../components/atoms/Button'
import { PriceCell } from '../components/atoms'
import {
    OptionCell,
    DatePickerField,
    Modal,
    DialogConfirm,
    TimePickerField, AddressCell,
} from '../components/molecules'
import { useTrips } from '../context/TripContext'
import { steps } from '../config/vars'
import useModal from '../utils/hooks/useModal'
import { UpdateWizardMode } from '../config/constants'
import { AnimatePresence, motion } from 'framer-motion'
import { adjustForDutchTimezone, formatDate } from '../utils/helper'

const STEP_FORWARD = 1
const STEP_BACKWARD = -1
const FINAL_STEP = 1

const UpdateWizard = ({ onClose, isOpen, mode }) => {
    const { t } = useTranslation()
    const { isOpen: isOpenAlert, closeModal: closeAlert, openModal: openAlert } = useModal()
    const {
        validateTrip,
        saveTrip,
        priceMutation,
        tripConfig,
        updateTripConfig,
        updateTripAttribute,
        writeableAttributes,
        clearExceptions,
        exceptions,
        price
    } = useTrips()
    const [currentStep, setCurrentStep] = useState(0)
    const [rand, setRand] = useState(0)

    const { data, mutate } = validateTrip

    // TODO - Fix this hack, price needs to change on input change
    useEffect(() => {
        if(mode === UpdateWizardMode.ATTRIBUTES) {
            priceMutation.mutate()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rand])

    useEffect(() => {
        setCurrentStep(0)
        clearExceptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    useEffect(() => {
        if (!!data) {
            const isInValid = data?.records.map((record) => record?.exceptions).flat().length > 0
            if (!isInValid) {
                setCurrentStep(currentStep + STEP_FORWARD)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const activeStep = useMemo(() => {
        if (currentStep === FINAL_STEP) {
            return 6;
        }
        return {
            [UpdateWizardMode.DATE]: 0,
            [UpdateWizardMode.TIME]: 1,
            [UpdateWizardMode.ATTRIBUTES]: 4,
            [UpdateWizardMode.COMMENT]: 5,
        }[mode] || 0
    }, [currentStep, mode])

    const onNavigate = async (direction) => {
        if (direction === STEP_FORWARD) {
            if (currentStep === FINAL_STEP) {
                await saveTrip.mutateAsync(true)
                onClose()
            } else {
                mutate()
            }
        } else if (direction === STEP_BACKWARD && currentStep > 0) {
            setCurrentStep(currentStep + STEP_BACKWARD)
        }
    }

    const getInput = () => {
        switch (mode) {
            case UpdateWizardMode.DATE:
                return (
                    <DatePickerField
                        label={t('booking.step.date.form.label')}
                        placeholder={t('booking.step.date.form.placeholder')}
                        defaultValue={ adjustForDutchTimezone(tripConfig?.pickupDateTime)} //new Date(tripConfig?.pickupDateTime)}
                        onChange={val => {
                            // set date to selected value and time to current value
                            const date = adjustForDutchTimezone(tripConfig?.pickupDateTime)
                            date.setFullYear(val.getFullYear())
                            date.setMonth(val.getMonth())
                            date.setDate(val.getDate())
                            updateTripConfig('pickupDateTime', date.toISOString())
                        }}
                    />)
            case UpdateWizardMode.TIME:
                return (
                    <TimePickerField
                        type="time"
                        htmlFor="pickupTime"
                        label={t('booking.step.time.form.label')}
                        placeholder={t('booking.step.time.form.placeholder')}
                        defaultValue={ adjustForDutchTimezone(tripConfig?.pickupDateTime) }
                        onChange={val => {
                            // set time to selected value and date to current value
                            const date = new Date(tripConfig?.pickupDateTime)
                            date.setHours(val.getHours())
                            date.setMinutes(val.getMinutes())
                            updateTripConfig('pickupDateTime', date.toISOString())
                        }}
                    />)
            case UpdateWizardMode.ATTRIBUTES:
                return (
                    <div className="flex flex-col w-full bg-white rounded-[20px] border border-grey-400">
                        {writeableAttributes().map((option, index) =>
                            <OptionCell
                                icon={`${index}-${option.key}`}
                                label={option.name}
                                key={option.id}
                                defaultValue={option.value}
                                type={option.type}
                                onChange={val => {
                                    // check if value is boolean
                                    if (typeof val === 'boolean') {
                                        val = val ? 1 : 0
                                    }
                                    updateTripAttribute(option.id, val)
                                    setRand(Math.random())
                                }}
                            />
                        )}
                    </div>
                )
            case UpdateWizardMode.COMMENT:
                return (
                    <textarea
                        className="w-full h-[200px] border border-grey-400 rounded-[20px] p-4"
                        placeholder={t('booking.step.notes.placeholder')}
                        defaultValue={tripConfig?.locationFrom?.additional || ''}
                        onChange={e => updateTripConfig('locationFrom',
                            {
                                ...tripConfig.locationFrom,
                                'additional': e.target.value
                            })}
                    />)
            default:
                return <></>
        }
    }

    return (!!tripConfig &&
        <Modal
            isOpen={isOpen}
            hideCloseButton={true}
            className="absolute top-0 bottom-0 w-full md:w-full max-w-full"
        >
            <div className="flex flex-col flex-start bg-white h-full flex-1">
                <div className="relative border-b-grey-400 border-b flex flex-row items-center px-4">
                    <h1 className="w-full text-center font-inter font-semibold text-primary-500 py-5">{t('update.title')}</h1>
                    <button tabIndex={0} onClick={openAlert} className="absolute right-4"><Close/></button>
                </div>

                <div className="w-full flex flex-1 z-20 relative overflow-auto mt-10">
                    <div className="w-full max-w-[400px] m-auto flex flex-col items-center lg:justify-center relative mt-0">
                        <div className="w-full flex-1">
                            <AnimatePresence>
                                <motion.div
                                    key="updateWizard"
                                    className="flex-1 md:flex-none w-full absolute"
                                    initial={{ opacity: 0, y: "0" }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: "0" }}
                                    transition={{ duration: 0.5 }}
                                >
                                    {exceptions?.length > 0 &&
                                        <div className="flex flex-col space-y-2 my-6">
                                            {exceptions?.map((item, index) =>
                                                <span
                                                    key={index}
                                                    className="text-red-500 text-center font-inter font-semibold">
                                                        {item}
                                                </span>
                                            )}
                                        </div>}
                                    <motion.h2 className="title text-center">{t(steps[activeStep].title)}</motion.h2>
                                    <motion.span className="font-inter text-center pt-2 block">
                                        <Trans i18nKey={steps[activeStep].body} values={{ date: formatDate(tripConfig.pickupDateTime) || ''}}/>
                                    </motion.span>
                                    <motion.div className="px-2.5 py-10 w-full">
                                        {currentStep === 0 ? getInput() :
                                            <div className="flex flex-col space-y-5 overflow-hidden">
                                                <AddressCell
                                                    trip={tripConfig}
                                                    className="rounded-[20px] border border-grey-400"
                                                />
                                            </div>}
                                    </motion.div>
                                </motion.div>
                            </AnimatePresence>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col ">
                    {mode === UpdateWizardMode.ATTRIBUTES && <PriceCell price={price}/>}
                    <div className="flex flex-row space-x-5 p-5 border-t-grey-400 border-t items-center justify-center">
                        {currentStep === 1 &&
                            <Button
                                state={States.OUTLINED}
                                className="px-6"
                                onClick={() => onNavigate(STEP_BACKWARD)}
                                tabIndex={0}
                            >
                            <Arrow className="fill-primary-500"/>
                        </Button>}
                        <Button
                            state={currentStep === FINAL_STEP ? States.ACTIVE : States.DEFAULT}
                            className="w-full md:max-w-[300px]"
                            onClick={() => onNavigate(STEP_FORWARD)}
                            tabIndex={0}
                        >
                            {t(currentStep < FINAL_STEP ? 'booking.button.next' : 'update.button.attributes')}
                        </Button>
                    </div>
                </div>
            </div>

            <DialogConfirm
                icon={<div className="w-12 h-12 bg-primary-200 flex items-center justify-center rounded-full">
                    <Error className="fill-primary-500"/>
                </div>}
                isOpen={isOpenAlert}
                onClose={closeAlert}
                onConfirm={() => {
                    setTimeout(() => {
                        onClose()
                    }, 0)
                    closeAlert()
                }}
                title={t('dialog.abort.title')}
                body={t('dialog.abort.body')}
                confirm={t('dialog.abort.button.confirm')}
                cancel={t('dialog.abort.button.cancel')}
            />
        </Modal>
    )
}

export default UpdateWizard
