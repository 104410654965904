import {Trans} from 'react-i18next'

const Tile = ({ title, body, icon, onClick, values = null, ...props }) => (
    <button className="flex flex-col tile" onClick={onClick} {...props} aria-label={title}>
        {icon}
        <div className="flex flex-col items-start">
            <h2 className="font-inter text-base font-bold text-primary-500 mb-1">{title}</h2>
            <span className="font-inter font-normal text-grey-600 text-sm text-left">
                <Trans i18nKey={body} values={values}/>
            </span>
        </div>
    </button>
)

export default Tile
