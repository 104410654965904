import React, {useLayoutEffect, useMemo} from 'react'
import { PriceCell, TripHeader } from '../components/atoms'
import {AddressCell, OptionCell} from '../components/molecules'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import { useTrips } from '../context/TripContext'
import { Dialog } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'
import { AnimatePresence, motion } from 'framer-motion'
import { ReactComponent as Close } from '../assets/icons/clost.svg'
import { useTranslation } from 'react-i18next'
import { getTripStatus } from '../utils/helper'
import TripStatusCell from '../components/atoms/TripStatusCell'
import TripActions from '../components/molecules/TripActions'

const TripDetail = ({isOpen, onClose, className}) => {
    const { planned, archived, isWritable } = useTrips()
    const { t } = useTranslation()
    let params = useParams()
    const navigate = useNavigate()
    const location = useLocation()

    const trip = useMemo(() => {
        const selectedTrip = [...planned?.trips, ...archived?.trips]?.find((trip) => trip.id === params.tripId);

        if(!selectedTrip && !!params.tripId) {
            // Handle the case when no matching trip is found.
            console.error('No trip found with id:', params.tripId)
            return
        }
        return selectedTrip
    }, [params, planned, archived])

    useLayoutEffect(() => {
        if(!trip && !!params.tripId) {
            navigate(location.pathname.replace(params.tripId, '')) // Redirect to the previous page if no trip is found.
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const attrs = useMemo(() => {
        const attributes = trip?.tripAttributes?.filter(att => isWritable(att) && att.numericValue > 0)

        return attributes?.map((attribute) => {
            const {attributeType, name} = attribute?.contractAttribute || {}
            const {numericValue} = attribute || {}
            return {
                title: name,
                key: attributeType?.key,
                value: numericValue,
                type: attribute?.booleanValue !== null ? "boolean" : "number"
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trip?.tripAttributes])

    return (
        <AnimatePresence>
            {isOpen && (
                <Dialog as="div" className="fixed inset-0 z-[90]" onClose={()=>{}} open={isOpen}>
                    <Dialog.Overlay
                        as={motion.div}
                        key="modal-trip-detail"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 0.5  }}
                        exit={{
                            opacity: 0,
                            transition: {
                                duration: 0.4,
                                ease: "easeIn"
                            },
                        }}
                        transition={{
                            duration: 0.3,
                            ease: "easeOut",
                        }}
                        className="fixed inset-0 bg-black opacity-50"
                    />
                    <div className="fixed inset-0">
                        <div className="flex items-end justify-end">
                            <Dialog.Panel
                                className={twMerge("relative w-full md:w-[450px]", className)}
                                as={motion.div}
                                key="modal"
                                initial={{ x: "100vh", scale: 1 }}
                                animate={{ x: 0, scale:1  }}
                                exit={{
                                    x: "100vh", scale: 1,
                                    transition: {
                                        duration: 0.5,
                                        ease: "easeIn"
                                    },
                                }}
                                transition={{
                                    duration: 0.5,
                                    ease: "easeOut",
                                }}
                            >
                                <div className="bg-white w-full h-screen flex flex-col">
                                    <div className="relative border-b-grey-400 border-b flex flex-row items-center px-4 bg-primary-500">
                                        <h1 className="w-full text-center font-inter font-semibold text-2xl text-white py-7">{t('trip.title')}</h1>
                                        <button tabIndex={0} onClick={onClose}><Close className="fill-white"/></button>
                                    </div>

                                    <div className="px-2.5 py-6 pt-8 overflow-auto h-full">
                                        <TripHeader
                                            timestamp={trip?.pickupDateTime}
                                            status={getTripStatus(trip)}
                                        />
                                        <AddressCell
                                            trip={trip}
                                            className="rounded-t-[20px] border border-grey-400"
                                        />
                                        <TripStatusCell
                                            status={trip?.tripOperation?.tripStatusType?.name}
                                            className={twMerge(
                                                "bg-white border-x border-b border-grey-400"
                                            )}
                                        />
                                        <TripActions trip={trip}/>
                                        <h2 className="font-inter text-xl mt-8 mb-2 text-grey-600 font-semibold px-3">
                                            Reizigers en bagage
                                        </h2>
                                        <div className="rounded-[20px] border border-grey-400 overflow-hidden shrink-0">
                                            {attrs?.map((option, index) => (
                                                <div
                                                    key={`${index}-${option.title}`}
                                                    className={twMerge(
                                                        "flex flex-row justify-between w-full px-5 py-4 space-x-3 border-grey-400",
                                                        index < attrs?.length - 1 ? "border-b" : ""
                                                    )}
                                                >
                                                    <OptionCell
                                                        icon={option.key}
                                                        label={option.title}
                                                        key={`${index}-${option.title}`}
                                                        defaultValue={option.value}
                                                        type={option.type}
                                                        readOnly={true}
                                                        className="p-0"
                                                    />
                                                </div>
                                            ))}
                                            {trip?.tripTariffs && <PriceCell
                                                price={{
                                                    total: trip?.tripTariffs[0]?.totalPrice || 0,
                                                    passengerCount: trip?.tripAttributes?.find((attr) =>
                                                        attr?.contractAttribute?.attributeType.key === "PAX").numericValue || 0
                                                }}
                                                className={twMerge(attrs?.length < 1 && "border-none")}/>}
                                        </div>
                                        <h2 className="font-inter text-xl mt-8 mb-2 text-grey-600 font-semibold px-3">Opmerking</h2>
                                        <textarea
                                            readOnly
                                            defaultValue={trip?.locationFrom?.additional || "Geen opmerkingen"}
                                            className={twMerge(
                                                "rounded-[20px] border border-grey-400 w-full h-[172px] p-4",
                                                trip?.locationFrom.additional ? "text-primary-500" : "text-grey-500")}
                                        />
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </div>
                    </div>
                </Dialog>
            )}
        </AnimatePresence>
    )
}

export default TripDetail
