import { useEffect, useState, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import { TripCell } from '../components/molecules'
import { EmptyMessage, Loader } from '../components/atoms'
import { useTranslation } from 'react-i18next'
import { useTrips } from '../context/TripContext'
import useModal from '../utils/hooks/useModal'
import TripDetail from './TripDetail'
import { ListTripAction } from '../config/constants'
import { Button, States } from '../components/atoms/Button'

const Tab = ({ children, active, onClick }) =>
    <button
        onClick={onClick}
        className={twMerge(
            "flex flex-row items-center justify-center w-1/2 lg:w-auto h-full font-inter text-sm md:text-base font-semibold",
            active ? ' text-primary-500 border-b-[3px] border-b-primary-500' : 'text-grey-500')}
    >
        {children}
    </button>

const Trips = () => {
    const [plannedActive, setPlannedActive] = useState(true)
    const navigate = useNavigate()
    const { t } = useTranslation()
    const loadingRef = useRef(false)
    const lastScrollTop = useRef(0)
    const { planned, archived, listTrips, isLoadingTrips } = useTrips()
    const { isOpen, closeModal, openModal } = useModal()
    const [sticky, setSticky] = useState("")
    const location = useLocation()
    let params = useParams()

    useEffect(() => {
        window.addEventListener('scroll', isSticky)
        return () => window.removeEventListener('scroll', isSticky)
    }, [])

    useEffect(() => {
        // Restore the scroll position after data is loaded
        const restoreScroll = () =>
            window.scrollTo({
                top: lastScrollTop.current,
                left: 0,
                behavior: "instant",
            })
        restoreScroll()
    }, [planned, archived])

    useEffect(() => {
        setPlannedActive(location.pathname.includes('gepland'))
        if (params?.tripId) {
            openModal()
        } else {
            closeModal()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    const isSticky = () => setSticky(window.scrollY > 0 ? "centered" : "")

    const title = () =>
        location.pathname.includes('gepland')
            ? <div className="flex items-center justify-center flex-row">
                Geplande ritten {planned?.trips?.length > 0 && <div className="ml-2.5 w-10 h-10 font-inter bg-secondary-300 rounded-[10px] flex items-center justify-center text-xl">{planned?.total}</div>}
            </div> : "Rithistorie"

    const fetchTrips = (restoreScroll = true) => {
        if (loadingRef.current) return // prevent multiple loads

        loadingRef.current = true

        // Save the current scroll position before loading more data
        lastScrollTop.current = restoreScroll ? window.scrollY : 0

        try {
            plannedActive ? listTrips(ListTripAction.PLANNED) : listTrips(ListTripAction.ARCHIVED)
        } finally {
            loadingRef.current = false
        }
    }

    const showLoadMore = () =>
        plannedActive ? planned?.total > planned?.trips?.length : archived?.total > archived?.trips?.length

    if (isLoadingTrips) return <Loader/>

    return (
        <div className="h-full container m-auto relative">
            <div className="w-full h-[60px] bg-transparent z-20">
                <div
                    className={twMerge(
                        "flex flex-row bg-white border border-grey-400 border-t-0 rounded-b-[20px] container h-[60px] overflow-hidden space-x-10 px-6 z-10",
                        sticky
                    )}
                >
                    <Tab active={plannedActive} onClick={() => navigate('/mijn-ritten/gepland')}>{`Geplande ritten (${planned?.total})`}</Tab>
                    <Tab active={!plannedActive} onClick={() => navigate('/mijn-ritten/rithistorie')}>Rithistorie</Tab>
                </div>
            </div>
            <div className="h-full flex flex-col">
                <div className="flex flex-row justify-between items-center">
                    <h1 className="hidden lg:flex font-inter font-semibold text-primary-500 text-[28px] px-5 py-11">
                        {title()}
                    </h1>
                    {/*{(!plannedActive && archived?.length > 0) && <MonthPicker/>}*/}
                </div>

                {((plannedActive && planned?.trips?.length > 0) || (!plannedActive && archived?.trips?.length > 0))
                    ? <div className="flex flex-col w-full">
                        <div className="grid grid-cols-1 px-2.5 md:px-0 md:grid-cols-2 mt-10 lg:mt-0 gap-10">
                            {(plannedActive ? planned : archived)?.trips?.map((trip, index) => (
                                <TripCell
                                    key={index}
                                    trip={trip}
                                    className="w-full"
                                />
                            ))}
                        </div>
                        <div className="w-full flex items-center justify-center mt-6">
                            {showLoadMore() && <Button
                                className="m-5 flex w-full max-w-[300px]"
                                state={States.OUTLINED}
                                onClick={fetchTrips}
                            >
                                {t('trips.button.load_more')}
                            </Button>}
                        </div>
                    </div>
                    : <EmptyMessage
                        title={t(`empty_message.${plannedActive ? 'planned' : 'history'}.title`)}
                        message={t(`empty_message.${plannedActive ? 'planned' : 'history'}.body`)}
                    />}
            </div>
            <TripDetail
                isOpen={isOpen}
                onClose={() => {
                    closeModal()
                    navigate(plannedActive ? '/mijn-ritten/gepland' : '/mijn-ritten/rithistorie')
                }}
                tripId={params.tripId}
            />
        </div>
    )
}

export default Trips
