import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SignUpForm } from '../components/organisms'

const SignIn = () => {
    const { t } = useTranslation()

    return (
        <div className="flex flex-col flex-1 h-full space-y-5">
            <SignUpForm/>
            <div className="absolute bottom-4 right-5 flex flex-row space-x-1">
                <span className="text-white font-inter text-sm">{t('sign_up.already_account')}</span>
                <Link to={'/inloggen'} className="text-white font-inter font-bold text-sm underline underline-offset-4">{t('sign_up.link_sign_in')}</Link>
            </div>
        </div>
    )
}

export default SignIn
