import {useMsal} from '@azure/msal-react'
import {InteractionStatus} from '@azure/msal-browser'

export const HTTPMethod = Object.freeze({
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    PATCH: 'patch',
    DELETE: 'delete',
})

const useApi = () => {
    const { instance, inProgress, accounts } = useMsal()

    const accessToken = async () => {
        if (inProgress === InteractionStatus.None && accounts?.length > 0) {

            const tokenRequest = {
                scopes: ["https://bcoptidev.onmicrosoft.com/e597c904-3e23-476c-adb7-e252d3384626/bookingportal"],
                account: accounts[0]
            }

            try {
                const response = await instance?.acquireTokenSilent(tokenRequest) ?? null;
                if (response) {
                    return response?.accessToken;
                }
                await instance?.logout();
            } catch (error) {
                console.error("Error acquiring token silently:", error);
                await instance?.logout();
            }
        }
    }

    const callApi = async (
        path,
        body,
        method = HTTPMethod.GET,
        secure = true,
        endpoint,
        customHeaders = null
    ) => {
        const headers = {
            'Content-Type': 'application/json',
        }

        if (customHeaders) {
            Object.assign(headers, customHeaders)
        }

        if (secure) {
            const token = await accessToken();
            if (token) {
                headers['Authorization'] = `Bearer ${token}`
            }
        }

        const response = await fetch(
            [].concat(endpoint, path).join('/'),
            Object.fromEntries(
                Object.entries({
                    method: method,
                    headers,
                    body:
                        method === HTTPMethod.GET ? null : JSON.stringify(body),
                }).filter(([key, value]) => !!value)
            )
        )

        if (!response.ok) {
            const { status, statusText } = response
            throw new Error(`response ${status}: ${statusText}`)
        }

        const contentLength = response.headers.get('content-length')
        const contentType = response.headers.get('content-type')

        if (contentLength && parseInt(contentLength) > 0) {
            return (contentType && contentType.includes('application/json')) ? response.json() : response.text()
        }

        // if response is readable, return json, otherwise return response
        return response.body && response.body.getReader ? response.json() : response


    }

    return { callApi }
}

export default useApi
