import React, {useEffect, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {ReactComponent as Close} from '../assets/icons/clost.svg'
import {ReactComponent as Arrow} from '../assets/icons/arrow-l.svg'
import {ReactComponent as Error} from '../assets/icons/error.svg'
import {Button, States} from '../components/atoms/Button'
import {PriceCell, ProgressBar} from '../components/atoms'
import {
    AddressCell,
    DatePickerField,
    DialogConfirm,
    Modal,
    OptionCell,
    PhoneInputField,
    TimePickerField,
} from '../components/molecules'
import {useTrips} from '../context/TripContext'
import {steps} from '../config/vars'
import {AnimatePresence, motion} from 'framer-motion'
import {useNavigate} from 'react-router-dom'
import useModal from '../utils/hooks/useModal'
import {formatDate} from '../utils/helper'

const STEP_FORWARD = 1
const STEP_BACKWARD = -1
const FINAL_STEP = 6

const initialValues= {
    current: 0,
    isPhoneValid: false,
}

const BookWizard = ({ onClose, isOpen }) => {
    const {
        initializeTrip,
        validateTrip,
        saveTrip,
        tripConfig,
        updateTripConfig,
        updateTripAttribute,
        writeableAttributes,
        exceptions,
        clearExceptions,
        price,
        reset,
    } = useTrips()
    const { t } = useTranslation()
    const [step, setStep] = useState(initialValues)
    const { isOpen: isOpenAlert, closeModal: closeAlert, openModal: openAlert } = useModal()
    const [isVisible, setIsVisible] = useState(false)
    const navigate = useNavigate()

    // Whenever currentStep changes, set isVisible to true
    // to trigger the transition animation.
    useEffect(() => {
        setIsVisible(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step.current])

    useEffect(() => {
        setStep(initialValues)
        initializeTrip()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const close = () => {
        setTimeout(() => {
            onClose()
            setStep(initialValues)
            reset()
        }, 0)
    }

    const onNavigate = async (direction) => {
        if (step.current === undefined || step.current === null) return

        if (direction === STEP_FORWARD && step.current === FINAL_STEP) {
            const resp = await saveTrip.mutateAsync()
            if (resp) {
                navigate('/mijn-ritten/gepland')
                close()
            }
        }

        if (direction === STEP_BACKWARD && step.current === 0) {
            close()
            return
        } // Do not proceed if we are at the first step

        try {
            // no validate trip when in the last step
            if (step.current !== steps.length - 1) {
                const validationResponse = await validateTrip.mutateAsync(step)
                const isInValid = validationResponse?.records?.map((record) => record?.exceptions).flat().length > 0
                setStep({...step})

                if (isInValid && step.current !== 0) {
                    if (direction === STEP_FORWARD) return
                    // Do not proceed if there are exceptions

                    if (direction === STEP_BACKWARD) clearExceptions()
                }
            }

            let adjustment = direction // Default adjustment is the direction (1 step forward or 1 step backward)

            // Adjust the step based on specific conditions to skip a step
            if (!tripConfig?.isReturn && ((direction === STEP_FORWARD && step.current === 1) || (direction === STEP_BACKWARD && step.current === 3))) {
                adjustment += direction // Skip a step
            }

            // Calculate the new step and ensure it’s within the limits
            const newStep = Math.max(0, Math.min(steps.length - 1, step.current + adjustment))

            // Update the current step
            setStep({...step, current: newStep})

        } catch (error) {
            console.error('Navigation failed:', error)
        }
    }

    const isValidStep = (idx) => {
        const currentStepExceptions = exceptions.find(item => item.step === idx)?.exceptions
        if (!currentStepExceptions) return true
        return currentStepExceptions && currentStepExceptions.length === 0
    }

    // DATE TIME STUFF
    const isToday = (date) => {
        if (!date) return false
        const today = new Date()
        const d = new Date(date)
        return d.getDate() === today.getDate() && d.getMonth() === today.getMonth() && d.getFullYear() === today.getFullYear()
    }

    /**
     * Returns the minimum pickup date and time based on trip configuration.
     *
     * @param {boolean} isReturn - Indicates if the trip is a return trip.
     * @returns {Date} - The calculated minimum pickup date and time.
     */
    const minimumPickupDateTime = (isReturn = false) => {
        const pickupDate =  new Date(tripConfig?.pickupDateTime)
        // Check if the pickup date is today
        if (isToday(pickupDate) && !isReturn) {
            //pickupDate.setHours(pickupDate.getHours(), pickupDate.getMinutes()+30, 0, 0)
            return new Date(tripConfig?.date)
        }
        // Set the pickup time based on whether it's a return trip
        if (isReturn) {
            // Add 30 minutes to the pickup time, and make sure the hours are also increased if necessary
            pickupDate.setMinutes(pickupDate.getMinutes() + 30)
        } else {
            pickupDate.setHours(8, 0, 0, 0)
        }
        return pickupDate
    }

    const getBody = () => {
        switch (step.current) {
            case 0:
                return (
                    <DatePickerField
                        label={t('booking.step.date.form.label')}
                        placeholder={t('booking.step.date.form.placeholder')}
                        defaultValue={new Date(tripConfig?.date)}
                        onChange={val => {
                            updateTripConfig('date', val.toISOString())
                            updateTripConfig('pickupDateTime', null)
                            updateTripConfig('returnDateTime', null)
                        }}
                    />)
            case 1:
                const defaultPickupTime = () => {
                    if (tripConfig?.pickupDateTime) {
                        return new Date(tripConfig?.pickupDateTime)
                    } else if (isToday(tripConfig?.date)) {
                        const now = new Date()
                        now.setHours(now.getHours() + 1)
                        // round to the nearest 5 minutes
                        now.setMinutes(Math.ceil(now.getMinutes() / 5) * 5)
                        return now
                    } else {
                        return null
                    }
                }

                return (
                    <TimePickerField
                        type="time"
                        htmlFor="pickupTime"
                        label={t('booking.step.time.form.label')}
                        placeholder={t('booking.step.time.form.placeholder')}
                        defaultValue={defaultPickupTime()}
                        min={ minimumPickupDateTime() }
                        max={new Date(tripConfig?.pickupDateTime).setHours(23, 59)}
                        onChange={val => {
                            const _d = new Date(tripConfig?.date)
                            _d.setHours(val.getHours())
                            _d.setMinutes(val.getMinutes())
                            updateTripConfig('pickupDateTime', _d.toISOString())
                        }}
                        hasError={!isValidStep(1)}
                    />)
            case 2:
                return (
                    <TimePickerField
                        type="time"
                        htmlFor="deliveryTime"
                        label={t('booking.step.time_return.form.label')}
                        placeholder={t('booking.step.time_return.form.placeholder')}
                        defaultValue={tripConfig?.returnDateTime ? new Date(tripConfig?.returnDateTime) : null}
                        min={minimumPickupDateTime(true)}
                        max={new Date(tripConfig?.pickupDateTime).setHours(23, 59)}
                        onChange={val => {
                            const _d = new Date(tripConfig?.pickupDateTime)
                            _d.setHours(val.getHours())
                            _d.setMinutes(val.getMinutes())
                            updateTripConfig('returnDateTime', _d.toISOString())
                        }}
                        hasError={!isValidStep(2)}
                    />)
            case 3:
                return (
                    <PhoneInputField
                        label={t('booking.step.phone.form.label')}
                        placeholder={t('booking.step.phone.form.placeholder')}
                        defaultValue={tripConfig?.phoneNumber}
                        onChange={val => updateTripConfig('phoneNumber', val)}
                        onValid={val => setStep({...step, isPhoneValid: val})}
                    />)
            case 4:
                return (
                    <div className="flex flex-col w-full bg-white rounded-[20px] border border-grey-400">
                        {writeableAttributes()?.map((option, index) =>
                            <OptionCell
                                icon={option.key}
                                label={option.name}
                                key={`${index}-${option.id}`}
                                defaultValue={option.value}
                                type={option.type}
                                placeholder={0}
                                onChange={val => {
                                    const normalizedValue = typeof val === 'boolean' ? (val ? 1 : 0) : val
                                    updateTripAttribute(option.id, normalizedValue)
                                }}
                            />
                        )}
                    </div>
                )
            case 5:
                return <div className="flex flex-col space-y-5 overflow-hidden">
                   <textarea
                       className="w-full h-[200px] border border-grey-400 rounded-[20px] p-4"
                       placeholder={t('booking.step.notes.placeholder')}
                       defaultValue={tripConfig?.locationFrom?.additional || ''}
                       onChange={e => updateTripConfig('locationFrom',
                           {
                               ...tripConfig.locationFrom,
                               'additional': e.target.value
                           })}
                   />
                </div>
            case 6:
                return <div className="flex flex-col space-y-5 overflow-hidden">
                    <AddressCell trip={tripConfig} className="rounded-[20px] border border-grey-400"/>
                    {tripConfig?.isReturn &&
                        <AddressCell
                            trip={tripConfig}
                            isReturn={tripConfig?.isReturn}
                            className="rounded-[20px] border border-grey-400"
                        />}
                </div>
            default:
                return <></>
        }
    }

    const getButtonState = () => {
        switch (step.current) {
            case 0:
            case 4:
            case 5:
                return States.DEFAULT
            case 1:
                return tripConfig?.pickupDateTime ? States.DEFAULT : States.DISABLED
            case 2:
                return tripConfig?.returnDateTime ? States.DEFAULT : States.DISABLED
            case 3:
                return tripConfig?.phoneNumber && step.isPhoneValid ? States.DEFAULT : States.DISABLED
            case 6:
                return States.ACTIVE
            default:
                return States.DISABLED
        }
    }

    return (
        <Modal
            onClose={close}
            isOpen={isOpen}
            hideCloseButton={true}
            className="absolute top-0 bottom-0 w-full md:w-full max-w-full"
        >
            <div className="flex flex-col flex-start bg-white h-full flex-1">

                <div className="relative border-b-grey-400 border-b flex flex-row items-center px-4">
                    <h1 className="w-full text-center font-inter font-semibold text-primary-500 py-5">{t('booking.title')}</h1>
                    <button tabIndex={0} onClick={openAlert} className="absolute right-4"><Close/></button>
                </div>

                <div className="w-full flex flex-1 z-20 relative overflow-auto">
                    <div className="w-full max-w-[400px] m-auto flex flex-col items-center lg:justify-center relative mt-0">
                        {exceptions?.length > 0 && (
                            <div className="flex flex-col space-y-2 my-6">
                                {exceptions?.map((item, index) => (
                                    <span key={index} className="text-red-500 text-center font-inter font-semibold">
                                        {item.message || JSON.stringify(item)} {/* Render the message property or stringify the object */}
                                    </span>
                                ))}
                            </div>
                        )}
                        {step?.current < steps.length -1 &&
                            <div className="flex items-center justify-center px-5 pt-10">
                                <ProgressBar progress={((step?.current + 1)/steps.length) * 100}/>
                            </div>}
                        <div className="w-full flex-1">
                            <AnimatePresence>
                                {isVisible &&
                                    <motion.div
                                        key={step?.current}
                                        className="px-5 flex-1 md:flex-none w-full absolute"
                                        initial={{ opacity: 0, y: "0" }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: "0" }}
                                        transition={{ duration: 0.5 }}
                                        variants={{ animate: {
                                                transition: { staggerChildren: 2 }, // Adjust the stagger delay as needed
                                            } }}
                                    >
                                        <motion.h2 className="title text-center pt-10">{t(steps[step?.current].title)}</motion.h2>
                                        <motion.span className="font-inter text-center pt-2 block">
                                            <Trans i18nKey={steps[step.current].body} values={{ date: formatDate(tripConfig?.pickupDateTime) || ''}}/>
                                        </motion.span>
                                        <motion.div className="px-2.5 py-10 w-full">{getBody()}</motion.div>
                                    </motion.div>
                                }
                            </AnimatePresence>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col ">
                    {(step?.current > steps.length - 4) && <PriceCell price={price}/>}
                    <div className="flex flex-row space-x-5 p-5 border-t-grey-400 border-t items-center justify-center">
                        <Button
                            state={States.OUTLINED}
                            className="px-6"
                            onClick={() => onNavigate(STEP_BACKWARD)} tabIndex={0}
                        >
                            <Arrow className="fill-primary-500"/>
                        </Button>
                        <Button
                            state={getButtonState()}
                            className="w-full md:max-w-[300px]"
                            onClick={() => onNavigate(STEP_FORWARD)}
                            tabIndex={0}
                        >
                            {t(step?.current < steps.length - 1 ? 'booking.button.next' : 'booking.button.confirm')}
                        </Button>
                    </div>
                </div>
            </div>

            <DialogConfirm
                icon={<div className="w-12 h-12 bg-primary-200 flex items-center justify-center rounded-full">
                    <Error className="fill-primary-500"/>
                </div>}
                isOpen={isOpenAlert}
                onClose={closeAlert}
                onConfirm={() => {
                    close()
                    closeAlert()
                }}
                title={t('dialog.abort.title')}
                body={t('dialog.abort.body')}
                confirm={t('dialog.abort.button.confirm')}
                cancel={t('dialog.abort.button.cancel')}
            />
        </Modal>
    )
}

export default BookWizard
