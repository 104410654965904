//import { Formik, Form } from 'formik'
//import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { AuthForm } from '../molecules'
import { Button, States } from '../atoms/Button'
import {useMsal} from "@azure/msal-react"

const SignInForm = () => {
    const { t } = useTranslation()
    const { instance } = useMsal()

    // const schema = Yup.object().shape({
    //     username: Yup.string().required(t('error.username.required')),
    //     password: Yup.string().required(t('error.password.required')),
    // })

    const handleLogin = async () => {
        try {
            await instance.loginRedirect();
        } catch (error) {
            console.error("Login error:", error)
        }
    }

    return (
        <AuthForm
            className="mt-12 mx-2.5 mb-6"
            title={t('sign_in.title')}
            body={'sign_in.body'}
        >

            <Button state={States.DEFAULT} onClick={handleLogin} className="w-full">login</Button>
            {/*<Formik
                initialValues={{
                    username: '',
                    password: ''
                }}
                onSubmit={({ username, password }) => alert(JSON.stringify({ username, password }))}
                validationSchema={schema}
                validateOnBlur={true}
                validateOnChange={false}
            >
                {
                    ({ isValid, dirty }) =>
                        <Form className="space-y-5">
                            <InputField
                                name="username"
                                placeholder={t('sign_in.form.email.placeholder')}
                                type="text"
                                autoComplete="email"
                                autoFocus={false}
                            />
                            <InputField
                                name="password"
                                autoComplete="current-password"
                                placeholder="*******"
                                showPasswordToggle
                                type="password"
                            />
                            <Link to={'/wachtwoord-vergeten'} className="text-primary-300 text-sm underline font-mint text-left block px-5">
                                {t('sign_in.forgot_password')}
                            </Link>
                            <div className="flex flex-row items-center justify-center w-full" style={{ marginTop: '32px' }}>
                                <Button
                                    state={( !isValid || !dirty) ? States.DISABLED : States.DEFAULT}
                                    className="w-full md:w-[350px]"
                                    type="submit"
                                >
                                    {t('sign_in.button.sign_in')}
                                </Button>
                            </div>
                        </Form>
                }
            </Formik>*/}
        </AuthForm>
    )
}

export default SignInForm
