//import { LogLevel } from "@azure/msal-browser"

export const msalConfig = {
    auth: {
        clientId: "e597c904-3e23-476c-adb7-e252d3384626",
        authority: "https://bcoptidev.b2clogin.com/bcoptidev.onmicrosoft.com/b2c_1_signin",
        knownAuthorities: ["bcoptidev.b2clogin.com"],
        redirectUri: window.location.origin + "/boeken",
        postLogoutRedirectUri: window.location.origin + "/inloggen",
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
    apiConfig: {
        scopes: ["https://bcoptidev.onmicrosoft.com/e597c904-3e23-476c-adb7-e252d3384626/bookingportal"],
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                // switch (level) {
                //     case LogLevel.Error:
                //         console.error(message);
                //         return;
                //     case LogLevel.Info:
                //         console.info(message);
                //         return;
                //     case LogLevel.Verbose:
                //         console.debug(message);
                //         return;
                //     case LogLevel.Warning:
                //         console.warn(message);
                //         return;
                //     default:
                //         return;
                // }
            },
        },
    },
}





// azureAd: {
//     msalConfig: {
//         auth: {
//             clientId: ""
//         },
//         apiConfig: {
//             scopes: ["https://bcoptidev.onmicrosoft.com/e597c904-3e23-476c-adb7-e252d3384626/bookingportal"],
//                 uri: "https://plusov.opt1dev.nl/api/"
//         },
//         b2cPolicies: {
//             names: {
//                 signUpSignIn: "b2c_1_signin"
//             },
//             authorities: {
//                 signUpSignIn: {
//                     authority: "https://bcoptidev.b2clogin.com/bcoptidev.onmicrosoft.com/b2c_1_signin"
//                 }
//             },
//             authorityDomain: "bcoptidev.b2clogin.com"
//         }
//     },
//     redirectUri: window.location.origin + "/booking-portal",
//         logoutRedirectUri: window.location.origin + "/booking-portal",
//         loginFailedUri: window.location.origin + "/booking-portal"
// },

// return new PublicClientApplication({
//     auth: {
//         clientId: environment.azureAd.msalConfig.auth.clientId,
//         authority: environment.azureAd.msalConfig.b2cPolicies.authorities.signUpSignIn.authority,
//         knownAuthorities: [environment.azureAd.msalConfig.b2cPolicies.authorityDomain],
//         redirectUri: environment.azureAd.redirectUri,
//         postLogoutRedirectUri: environment.azureAd.logoutRedirectUri
//     }
// });
// }
