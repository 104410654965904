import {Link, Outlet} from 'react-router-dom'
import {ReactComponent as Logo} from '../assets/logo/logo.svg'

const Auth = () => {
    return (
        <div
            className="relative h-full scrollbar scrollbar-w-[2px] scrollbar-thumb-primary-300 scrollbar-track-transparent md:overflow-auto">
            <div className="relative z-10 w-full h-full py-16 flex flex-col items-center">
                <Link to={'/'}><Logo/></Link>
                <Outlet />
            </div>

            <div className="bg-lines bg-no-repeat bg-[center_bottom] bg-contain fixed bottom-0 w-full h-full z-0"/>
        </div>
    )
}

export default Auth
