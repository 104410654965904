import { AddressCell } from './index'
import { TripHeader } from '../atoms'
import { getTripStatus } from '../../utils/helper'
import { Link } from 'react-router-dom'
import { ReactComponent as Arrow } from '../../assets/icons/arrow-r.svg'
import TripStatusCell from '../atoms/TripStatusCell'
import { TripStatus } from '../../config/constants'

const TripCell = ({trip }) => {

    return (
        <div className="flex flex-col">
            <TripHeader
                timestamp={trip.desiredTimeType.name === "PICKUP" ? trip.pickupDateTime : trip.deliveryDateTime}
                status={getTripStatus(trip)}
            />
            <AddressCell
                trip={trip}
                className="rounded-t-[20px] border border-grey-400"
            />
            <TripStatusCell
                status={trip?.tripOperation?.tripStatusType?.name}
                className="py-6 bg-white border-x border-b border-grey-400"
            />
            <div className="w-full shrink-0 rounded-b-[20px] bg-white border-t-0 border border-grey-400 overflow-hidden">
                {trip?.tripOperation?.tripStatusType?.name === TripStatus.ARCHIVED ||
                trip?.tripOperation?.tripStatusType?.name === TripStatus.CANCEL ?
                    (
                        <div className="flex flex-row items-center justify-between w-full h-full">
                            <Link to={"/mijn-ritten/rithistorie/" + trip.id} className="action-link py-4">
                                Rit bekijken <Arrow className="fill-primary-400 ml-2 w-6 h-6"/>
                            </Link>
                        </div>
                    ) :
                    <Link to={"/mijn-ritten/gepland/" + trip.id} className="action-link py-4">
                        Meer informatie <Arrow className="fill-primary-400 ml-2"/>
                    </Link>}
            </div>
        </div>
    )
}

export default TripCell
