import {AccessType, AttributeType, BASE_URL, ListTripAction, LocationType} from '../config/constants'
import React, { createContext, useEffect, useState } from 'react'
import useApi, { HTTPMethod } from '../utils/hooks/useApi'
import { useCreateTrip, useListTrips } from '../utils/fetch'
import { generateGUID } from '../utils/helper'
import toast from 'react-hot-toast'
import { useAccount } from './AccountContext'
import { useMutation } from '@tanstack/react-query'

const TripContext = createContext(null)

const emptyTripConfig = {
    locationFrom: null,
    locationTo: null,
    isReturn: true,
    minimumPickupDateTime: new Date(),
    date: new Date(),
    pickupDateTime: null,
    returnDateTime: null,
    phoneNumber: null,
    tripAttributes: null,
}

export const TripProvider = ({ children }) => {
    const { selectedAccount, getLocationOfType } = useAccount()
    const [tripModel, setTripModel] = useState()
    const [paxId, setPaxId] = useState()
    const [exceptions, setExceptions] = useState([])
    const [wizardStep, setWizardStep] = useState(0)
    const [attrs, setAttrs] = useState()
    const [tripConfig, setTripConfig] = useState(emptyTripConfig)
    const [price, setPrice] = useState({total: 0, passengerCount: 0})

    const {planned, archived, isLoading, listTrips, clearArchivedTrips, clearPlannedTrips} = useListTrips()
    const createTripMutation = useCreateTrip()
    const {callApi} = useApi()

    useEffect(() => {
        if (selectedAccount) {
            resetTripConfig()
            initializeTrip()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount])

    useEffect(() => {
        if (tripConfig) updateTripModel()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tripConfig])

    useEffect(() => {
        if (wizardStep === 3) priceMutation.mutate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tripModel])

    const resetTripConfig = () => {
        setTripConfig(emptyTripConfig)
    }

    const initializeTrip = async () => {
        try {
            const model = await createTripMutation.mutateAsync(createTripPayload())
            setTripAttributes(model)
            setTripModel(model)
            updateTripConfigFromModel(model)
        } catch (error) {
            console.error(error)
        }
    }

    const createTripPayload = () => ({
        id: selectedAccount?.id,
        invokerId: generateGUID(),
        correlationId: generateGUID(),
        initializers: [{
            customerSubcontractId: selectedAccount?.customerSubcontracts[0]?.id,
            templateId: null,
            continueTrip: null,
        }],
    })

    const setTripAttributes = (model) => {
        const attributes = model?.records[0]?.tripAttributes
        setAttrs(attributes)
        setPaxId(attributes.find(attr => attr?.contractAttribute?.attributeType?.key === 'PAX')?.contractAttribute?.id)
    }

    const updateTripConfigFromModel = (model) => {
        const {locationFrom, phoneNumber, pickupDateTime, tripAttributes} = model?.records[0]
        setTripConfig(prevConfig => ({
            ...prevConfig,
            locationFrom,
            phoneNumber,
            tripAttributes,
            minimumPickupDateTime: pickupDateTime,
        }))
    }

    const updateTripConfig = (key, value) => {
        setTripConfig(prevConfig => ({...prevConfig, [key]: value}))
    }

    const updateTripModel = () => {
        const updatedRecord = getUpdatedRecord()
        const records = getRecords(updatedRecord)
        setTripModel(prevModel => ({...prevModel, records}))
    }

    const getUpdatedRecord = () => {
        const {pickupDateTime, locationFrom, locationTo, phoneNumber, tripAttributes} = tripConfig
        return {pickupDateTime, locationFrom, locationTo, phoneNumber, tripAttributes}
    }

    const getRecords = (updatedRecord) => {
        const {isReturn, pickupDateTime, returnDateTime, locationFrom, locationTo} = tripConfig
        return isReturn ? [
            {...tripModel?.records[0], ...updatedRecord},
            {
                ...tripModel?.records[0],
                ...updatedRecord,
                pickupDateTime: returnDateTime || pickupDateTime,
                locationFrom: {...locationTo, id: generateGUID(), version: generateGUID()},
                locationTo: {...locationFrom, id: generateGUID(), version: generateGUID()}
            },
        ] : [{...tripModel?.records[0], ...updatedRecord}]
    }

    const payload = (update = false) => {
        const modifiedRecords = tripModel?.records.map(record => createRecord(record, update))
        return {
            correlationId: generateGUID(),
            id: update ? tripModel.id : generateGUID(),
            invokerId: generateGUID(),
            records: modifiedRecords,
        }
    }

    const createRecord = (record, update) => {
        const guid = update ? tripConfig.id : generateGUID()
        const newRecord = {
            ...record,
            id: guid,
            isChanged: true,
            isNew: !update,
            tripAttributes: mapTripAttributes(guid, record, update),
            tripBudgets: [],
            tripPlanParameters: [],
            tripTariffTypes: [],
            tripOperation: createOperation(record),
            tripOperationTimes: createTripOperationTimes(record),
            booking: createBooking(record, update),
        }
        if (update) setIsNewToFalse(newRecord)
        return newRecord
    }

    const createOperation = (record) => ({
        ...record.tripOperation,
        id: generateGUID(),
        version: generateGUID(),
    })

    const createTripOperationTimes = (record) => ({
        ...record.tripOperationTimes,
        id: generateGUID(),
        version: generateGUID(),
    })

    const createBooking = (record, update) => ({
        ...record.booking,
        id: update ? record.booking.id : generateGUID(),
        version: generateGUID(),
        isChanged: true,
    })

    const setIsNewToFalse = (obj) => {
        Object.keys(obj).forEach(key => {
            if (typeof obj[key] === 'object' && obj[key] !== null && obj[key].hasOwnProperty('isNew')) {
                obj[key].isNew = false
            }
        })
    }

    const mapTripAttributes = (tripId, record, update) => {
        const formattedAttrs = record.tripAttributes || tripModel?.records[0]?.tripAttributes
        return formattedAttrs?.map(attribute => ({
            ...attribute,
            id: generateGUID(),
            isChanged: true,
            isNew: !update,
            trip: {id: tripId, version: generateGUID()},
            version: generateGUID(),
        }))
    }

    const updateTripAttribute = (attrId, value) => {
        const updateAttributes = (attributes) => attributes.map(attr =>
            attr?.contractAttribute?.id === attrId
                ? { ...attr, numericValue: parseInt(value), ...attr?.booleanValue !== null ? {booleanValue: Boolean(value)} : null }
                : attr
        );

        setTripModel(prevModel => ({
            ...prevModel,
            records: prevModel?.records.map(record => ({
                ...record,
                tripAttributes: updateAttributes(record?.tripAttributes),
            })),
        }));

        setTripConfig(prevConfig => ({
            ...prevConfig,
            tripAttributes: updateAttributes(prevConfig?.tripAttributes),
        }));
    };

    const writeableAttributes = () => {
        const attributes = mapAttributes()
        return attributes?.filter(isWritable)?.map(formatAttribute)
    }

    const mapAttributes = () => {
        return tripModel?.records[0]?.tripAttributes?.map(attr => {
            const matchingItem = attrs?.find(item => item?.contractAttribute?.id === attr?.contractAttribute?.id)
            return matchingItem ? {...attr, contractAttribute: matchingItem?.contractAttribute} : attr
        }).filter(Boolean)
    }

    const isWritable = (attribute) => {
        return attribute?.contractAttribute?.accessType?.name !== AccessType.HIDDEN &&
            attribute?.contractAttribute?.accessType?.name !== AccessType.SYSTEM &&
            attribute?.isPrimary &&
            attribute?.contractAttribute?.attributeType?.key !== AttributeType.PRIORITY &&
            attribute?.contractAttribute?.attributeType?.key !== AttributeType.FORCE_DIRECT_BOOKING &&
            attribute?.contractAttribute?.attributeType?.key !== 'PAX'
    }

    const formatAttribute = (attribute) => {
        const {attributeType} = attribute?.contractAttribute || {}
        return {
            id: attribute?.contractAttribute?.id,
            name: attribute?.contractAttribute?.name,
            key: attributeType?.key,
            value: attribute?.numericValue || attribute?.textValue || attribute?.booleanValue || 0,
            type: attributeType?.dataType?.name,
        }
    }

    const explodeAndRemoveValueKeys = (obj) => {
        if (Array.isArray(obj)) {
            return obj.map(item => explodeAndRemoveValueKeys(item))
        } else if (typeof obj === 'object' && obj !== null) {
            if (obj.hasOwnProperty('value')) {
                return explodeAndRemoveValueKeys(obj.value)
            }
            const newObj = {}
            for (const key in obj) {
                newObj[key] = explodeAndRemoveValueKeys(obj[key])
            }
            return newObj
        }
        return obj
    }

    const addPropertiesToObject = (obj) => {
        if (typeof obj === 'object' && obj !== null) {
            if ('isChanged' in obj || 'isDeleted' in obj || 'isNew' in obj) {
                obj.isChanged = obj.isChanged || true
                obj.isDeleted = obj.isDeleted || false
                obj.isNew = obj.isNew || true
            }
            for (const key in obj) {
                obj[key] = addPropertiesToObject(obj[key])
            }
        }
        return obj
    }

    const updateExceptionsAndPrice = (data) => {
        if (data?.records) {
            setExceptions(prevExceptions => {
                const newExceptions = data?.records?.map(record => record?.exceptions).flat() || []
                const uniqueMessages = [...new Set(newExceptions.flatMap(exception => exception?.message))]
                return (newExceptions.length === 0) ? [] : uniqueMessages
            })
            setPrice({
                total: data?.records[0]?.value?.tripTariffTypes[0]?.value?.totalPrice?.value || 0,
                passengerCount: data?.records[0]?.value?.tripAttributes?.find(attr =>
                    attr?.value?.contractAttribute?.value?.id === paxId)?.value?.numericValue?.value || 0,
            })
        }
    }

    const validateTrip = useMutation({
        mutationFn: async (step) => {
            setWizardStep(step?.current)
            return callApi('trip/ValidateTripStorageModels', payload(), HTTPMethod.POST, true, BASE_URL)
        },
        onSuccess: (data) => {
            if (data.isSucceeded) {
                setTripModel(prevModel => ({
                    ...prevModel,
                    records: addPropertiesToObject(explodeAndRemoveValueKeys(data?.records)),
                }))
                updateExceptionsAndPrice(data)
            } else {
                toast.error(data.exception.message)
            }
        },
        onError: (error) => toast.error(error.message),
    })

    const priceMutation = useMutation({
        mutationFn: async () => callApi('trip/ValidateTripStorageModels', payload(), HTTPMethod.POST, true, BASE_URL),
        onSuccess: (data) => updateExceptionsAndPrice(data),
        onError: (error) => toast.error(error.message),
    })

    const saveTrip = useMutation({
        mutationFn: async (update) => {
            clearPlannedTrips()
            return callApi('trip/SaveTripStorageModels', payload(update), HTTPMethod.POST, true, BASE_URL)
        },
        onSuccess: (data) => {
            if (data.isSucceeded) {
                listTrips(ListTripAction.PLANNED)
                toast.success('De boeking is succesvol opgeslagen')
                setTripConfig({
                    ...emptyTripConfig,
                    locationFrom: getLocationOfType(LocationType.HOME, selectedAccount)
                })
            } else {
                toast.error(data.exception.message)
            }
            return data
        },
        onError: (error) => toast.error(error.message),
    })

    return (
        <TripContext.Provider value={{
            initializeTrip,
            validateTrip,
            saveTrip,
            priceMutation,
            exceptions,
            clearExceptions: () => setExceptions([]),
            tripConfig,
            updateTripConfig,
            updateTripAttribute,
            writeableAttributes,
            planned,
            archived,
            listTrips,
            resetTrips: () => {
                clearArchivedTrips()
                clearPlannedTrips()
            },
            reset: () => {
                resetTripConfig()
                initializeTrip()
                setExceptions([])
            },
            isLoadingTrips: isLoading,
            price,
            isWritable
        }}>
            {children}
        </TripContext.Provider>
    )
}

export const useTrips = () => {
    const context = React.useContext(TripContext)
    if (context === undefined) {
        throw new Error("useTrips must be used within a TripProvider")
    }
    return context
}
